import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import styled from "styled-components";

const StyledFileChip = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
  margin-bottom: 8px;
  border: 1px solid #b0bec5;
  border-radius: 8px;
  background-color: #eceff1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FileChip = ({ file, onDelete }) => (
  <StyledFileChip>
    <Box display="flex" alignItems="center" style={{ gap: 8 }}>
      <InsertDriveFileIcon style={{ color: "#546e7a" }} />
      <Typography variant="body2" style={{ wordBreak: "break-word" }}>
        <strong>{file.name}</strong> – {(file.size / 1024).toFixed(2)} KB
      </Typography>
    </Box>
    <Button size="small" color="primary" onClick={onDelete} variant="contained">
      Remove
    </Button>
  </StyledFileChip>
);

export default FileChip;

import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Skeleton from "@material-ui/lab/Skeleton";
import { FieldServicesAppRoutes } from "../lib/constants";

export const PageRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const PageContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;

const StyledAppBar = styled(AppBar)`
  top: auto;
`;

const HeaderToolbar = styled(Toolbar)`
  justify-content: space-between;

  margin: 12px 16px;

  ${(props) => props.theme.breakpoints.only("xs")} {
    margin: 8px 16px;
  }
`;

const Logo = styled.img`
  height: 48px;

  ${(props) => props.theme.breakpoints.only("xs")} {
    height: 40px;
  }

  width: auto;
  margin-right: 16px;
`;

const TitleToolbar = styled(Toolbar)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  gap: 16px;
  padding: 0 16px;
`;

const Title = styled(Typography)`
  flex-grow: 1;
  text-align: center;
`;

const StyledBackButton = styled(Button)`
  width: 200px;
`;

const BackButton = ({ to, label = "Back" }) => {
  const { isXs, isSm } = useBreakpoints();
  const buttonSize = isSm ? "medium" : "large";

  return isXs ? (
    <Tooltip title={label}>
      <IconButton component={Link} to={to} color="inherit" size="small">
        <ArrowBack />
      </IconButton>
    </Tooltip>
  ) : (
    <StyledBackButton
      component={Link}
      to={to}
      variant="outlined"
      startIcon={<ArrowBack />}
      color="inherit"
      size={buttonSize}
    >
      {label}
    </StyledBackButton>
  );
};

// App bar with logo and feature title
export const PageAppBar = ({ title, buttonPath, buttonLabel }) => {
  const { isXs } = useBreakpoints();
  const toolbarVariant = isXs ? "dense" : "regular";
  const header = isXs ? "Field Services" : "BSEACD Field Services";
  const typographyVariant = isXs ? "h6" : "h5";

  return (
    <>
      <StyledAppBar position="fixed" color="default">
        <HeaderToolbar variant={toolbarVariant}>
          <Logo src="/static/img/bseacd-logo.png" alt="BSEACD Logo" />
          <Typography variant={typographyVariant}>{header}</Typography>
        </HeaderToolbar>

        <TitleToolbar variant={toolbarVariant}>
          <Title variant={typographyVariant}>{title}</Title>
          <BackButton to={buttonPath} label={buttonLabel} />
        </TitleToolbar>
      </StyledAppBar>

      <HeaderToolbar variant={toolbarVariant} />
      <TitleToolbar variant={toolbarVariant} />
    </>
  );
};

const StyledNavigationButton = styled(Button)`
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  padding: 16px;
`;

// Large stretch/grow nav buttons
export const NavigationButton = ({ label, to, redirect, ...rest }) => {
  const { isXs } = useBreakpoints();
  const typographyVariant = isXs ? "h5" : "h4";

  return (
    <StyledNavigationButton
      component={Link}
      to={redirect ? `${to}?redirect=${redirect}` : to}
      variant="contained"
      color="primary"
      fullWidth
      {...rest}
    >
      <Typography variant={typographyVariant}>{label}</Typography>
    </StyledNavigationButton>
  );
};

const StyledErrorCard = styled(Card)`
  margin-top: 120px;
  padding: 24px 16px;

  ${(props) => props.theme.breakpoints.only("sm")} {
    margin-top: 80px;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-top: 24px;
  }

  .MuiCardContent-root {
    text-align: center;
  }
`;

const ErrorButton = styled(Button)`
  margin-top: 16px;
  width: 120px;
`;

export const ErrorCard = ({
  title = "Something went wrong",
  message = "An unexpected error occurred. Please try again.",
  onRetry = () => {},
  isFetching = false,
}) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} sm={9} md={8} lg={6}>
        <StyledErrorCard>
          <CardContent>
            <Typography variant="h6" align="center" gutterBottom>
              {title}
            </Typography>
            {message && (
              <Typography variant="body2" align="center" gutterBottom>
                {message}
              </Typography>
            )}
            {onRetry && (
              <ErrorButton
                variant="outlined"
                color="secondary"
                onClick={onRetry}
                disabled={isFetching}
              >
                {isFetching ? "Retrying..." : "Retry"}
              </ErrorButton>
            )}
          </CardContent>
        </StyledErrorCard>
      </Grid>
    </Grid>
  );
};

// Large skeleton loader for content and action button
export const ContentSkeleton = () => {
  const { isDownSm } = useBreakpoints();
  const gap = isDownSm ? "12px" : "24px";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      style={{ gap }}
    >
      <Skeleton variant="rect" width="100%" height="100%" />
      <Skeleton variant="rect" width="150px" height="36px" />
    </Box>
  );
};

export const FlexBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSaveButton = styled(Button)`
  width: 150px;
`;

// Placeholder content card and action button
export const ContentPlaceholder = ({
  children = "Content goes here...",
  buttonLabel = "Save",
  id,
}) => {
  const { isDownSm, isXs, isSm } = useBreakpoints();
  const gap = isDownSm ? "12px" : "24px";
  const buttonSize = isXs ? "small" : isSm ? "medium" : "large";

  return (
    <FlexBox style={{ gap }}>
      <FlexBox component={Paper}>{children}</FlexBox>
      <StyledSaveButton
        variant="contained"
        color="primary"
        component={Link}
        to={FieldServicesAppRoutes.WELL_ACTIONS(id)}
        size={buttonSize}
      >
        {buttonLabel}
      </StyledSaveButton>
    </FlexBox>
  );
};

// Grid container for consistent layout
export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 2,
  justify: "center",
  alignItems: "stretch",
})`
  flex-grow: 1;
`;

// Grid item for layout consistency
export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: props.xs ?? 12,
  sm: props.sm ?? 6,
  md: props.md ?? 4,
  lg: props.lg ?? 3,
}))`
  display: flex;
  flex-direction: column;
`;

import React from "react";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes, HOME_ACTIONS } from "../lib/constants";
import {
  NavigationButton,
  PageContainer,
  PageAppBar,
  GridContainer,
  GridItem,
  PageRoot,
} from "../components/ui";

const HomePage = function () {
  return (
    <PageRoot>
      <Helmet title="Field Services Home" />

      <PageAppBar
        title={<strong>Home</strong>}
        buttonPath={FieldServicesAppRoutes.DASHBOARD()}
        buttonLabel="Main Dashboard"
      />

      <PageContainer>
        <GridContainer>
          {HOME_ACTIONS.map((action, index) => (
            <GridItem key={index} lg={4}>
              <NavigationButton to={action.route} label={action.label} />
            </GridItem>
          ))}
        </GridContainer>
      </PageContainer>
    </PageRoot>
  );
};

export default HomePage;

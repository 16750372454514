import React, { useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import { useDynamicForm } from "../hooks/useDynamicForm";
import { waterLevelsFormConfig } from "../formConfigs/waterLevelsFormConfig";
import { DynamicForm } from "../forms/DynamicForm";
import { useAuth0 } from "@auth0/auth0-react";

const IS_NEW_RECORD = true;

const WaterLevelsPage = function () {
  const { user } = useAuth0();

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useSelectedWell(id);
  const wellNdx = data?.well_ndx || null;

  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirect");

  const handleRedirect = () =>
    history.push(redirectPath || FieldServicesAppRoutes.WELL_ACTIONS(id));

  const config = useMemo(() => waterLevelsFormConfig(user?.sub), [user]);

  const {
    isLoading: isLoadingFormData,
    error: formDataError,
    refetchLookups,
    ...rest
  } = useDynamicForm({
    ndx: "",
    isNewRecord: IS_NEW_RECORD,
    config: config,
    endpoints: {
      submit: `/well-actions/${wellNdx}/water-levels`,
    },
    handleRedirect,
  });

  const anyError = error || formDataError;
  const loading = isFetching || isLoadingFormData;

  const retryActions = [refetch, refetchLookups].filter(Boolean);

  return (
    <PageRoot>
      <Helmet title="Water Levels | Field Services" />

      <PageAppBar
        title={
          <>
            <strong>New GW Level Entry:</strong> {id}
          </>
        }
        buttonPath={redirectPath || FieldServicesAppRoutes.WELL_ACTIONS(id)}
        buttonLabel={redirectPath ? "Back" : "Well Actions"}
      />

      <PageContainer>
        {anyError ? (
          <ErrorCard
            onRetry={() => Promise.all(retryActions.map((action) => action()))}
            isFetching={loading}
          />
        ) : loading ? (
          <ContentSkeleton />
        ) : (
          <DynamicForm config={config} {...rest} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WaterLevelsPage;

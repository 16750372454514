import { ValidationSchemas, lookupConfig } from "./formConstants";

export const createWellFormConfig = [
  {
    title: "Identity and Ownership",
    fields: [
      {
        name: "state_well_num",
        label: "State Well Number",
        type: "text",
        validation: ValidationSchemas.string,
        infoTooltip: "The official well number assigned by the state.",
      },
      {
        name: "well_name",
        label: "Well Name",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Classification and Reference",
    fields: [
      {
        name: "well_class_ndx",
        label: "Well Classification",
        type: "selectSingle",
        lookupTable: lookupConfig.wellClassification,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
      {
        name: "well_subclass_ndx",
        label: "Well Subclass",
        type: "selectSingle",
        lookupTable: lookupConfig.wellSubclass,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
    ],
  },
  {
    title: "Use",
    fields: [
      {
        name: "primary_use_ndx",
        label: "Primary Use",
        type: "selectSingle",
        lookupTable: lookupConfig.wellUse,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
    ],
  },
  {
    title: "Location",
    fields: [
      {
        name: "physical_location_address",
        label: "Physical Address",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "city",
        label: "City",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "location_map",
        type: "map",
        label: "Location Selection",
      },
      {
        name: "lat_dd",
        label: "Latitude",
        type: "number",
        validation: ValidationSchemas.coordinate.lat,
      },
      {
        name: "lon_dd",
        label: "Longitude",
        type: "number",
        validation: ValidationSchemas.coordinate.lon,
      },
      {
        name: "elevation_ft",
        label: "Elevation (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "elevation_source",
        label: "Elevation Source",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Record Tracking",
    fields: [
      {
        name: "is_published",
        label: "Published?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
        readOnly: true,
      },
      {
        name: "removed",
        label: "Removed?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
        readOnly: true,
      },
      {
        name: "created_timestamp",
        label: "Created Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
        adminOnly: true,
      },
      {
        name: "created_by",
        label: "Created By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
        adminOnly: true,
      },
      {
        name: "updated_timestamp",
        label: "Updated Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
        adminOnly: true,
      },
      {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
        adminOnly: true,
      },
    ],
  },
];

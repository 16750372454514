import React from "react";
import { Link } from "react-router-dom";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { IconButton, Typography, Paper, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components/macro";

const ListContainer = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-bottom: none;
  }
`;

const RecordText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MobileNewButton = styled(IconButton)`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 50px;
  padding: 6px 12px;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }

  svg {
    margin-right: 4px;
  }
`;

const WellInspectionList = ({ wellId, inspections }) => {
  const { isXs } = useBreakpoints();
  const newUrl = `/field-services/well-actions/${wellId}/inspection/new`;

  return (
    <ListContainer>
      <Header>
        <Typography variant="h6">Inspection Records</Typography>
        {isXs ? (
          <MobileNewButton component={Link} to={newUrl}>
            <AddIcon fontSize="small" />
            New
          </MobileNewButton>
        ) : (
          <Button
            component={Link}
            to={newUrl}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        )}
      </Header>

      {(!inspections || inspections.length === 0) && (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ padding: "12px 0" }}
        >
          No inspection records found.
        </Typography>
      )}

      {inspections?.map((insp) => {
        const editUrl = `/field-services/well-actions/${wellId}/inspection/${insp.ir_ndx}`;
        return (
          <ListItem key={insp.ir_ndx}>
            <RecordText>
              <Typography variant="body1">
                Date: {insp.inspection_date?.substring(0, 10)}
              </Typography>
              <Typography variant="body2">
                Type: {insp.ir_type_desc || insp.ir_type_ndx}
              </Typography>
            </RecordText>
            {isXs ? (
              <IconButton component={Link} to={editUrl} color="secondary">
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                component={Link}
                to={editUrl}
                variant="outlined"
                color="secondary"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            )}
          </ListItem>
        );
      })}
    </ListContainer>
  );
};

export default WellInspectionList;

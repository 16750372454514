import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Map from "./map";
import WellStylesControl from "./controls/wellStylesControl";
import ZoomInfo from "./controls/zoomInfo";
import Search from "./filters/search";
import FilterControl from "./filters/filterControl";
import Filter from "./filters/filter";

import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";
import useLayerStyles from "./hooks/useLayerStyles";
import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";
import { isTouchScreenDevice } from "../../utils";
import useTheme from "@material-ui/core/styles/useTheme";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import LegendControl from "./controls/LegendControl";
import Legend from "./components/Legend";
import { RotateLeft } from "@material-ui/icons";
import DataVizControl from "./controls/dataVizControl";
import DataViz from "./components/DataViz";
import useSources from "./hooks/useSources";

const FiltersBarRoot = styled(Paper)`
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  height: 90px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const PublicMap = () => {
  const { sources } = useSources();

  const mapContainer = useRef(null);
  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    zoomLevel,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateLayerBooleanFilter,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    dataVizVisible,
    setDataVizVisible,
    dataVizWell,
    dataVizGraphType,
    eventsRegistered,
    isMapLoaded,
    wells,
    searchRadiusBuffersAdjacentWells,
    handleEnableSearchRadiusControlAdjacentWells,
    updateSearchRadiusBuffersAdjacentWells,
    handleClearSearchRadiusBuffersAdjacentWells,
    resetSearchRadiusBuffersAdjacentWells,
    intersectWellsEnabled,
    setIntersectWellsEnabled,
    addBuffersToMap,
    addBuffersToMapAdjacentWells,
  } = useMap(mapContainer, INIT_MAP_CONFIG, sources);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    handleResetAll,
  } = useFilters({ onFilterChange: updateLayerFilters, isMapLoaded });
  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
  });
  const theme = useTheme();

  const splitButtonOptionsFilters = [
    filterValues?.wellClass,
    filterValues?.wellSubclass,
    filterValues?.monitoringStatus,
    filterValues?.primaryUse,
    filterValues?.managementZone,
    filterValues?.aquiferCompletion,
    filterValues?.aquiferSetting,
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [legendVisible, setLegendVisible] = useState(true);

  const handleSearchSelect = useCallback(
    (result) => {
      map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
    },
    [map]
  );

  const handleDataVizClose = useCallback(
    () => setDataVizVisible((p) => !p),
    [setDataVizVisible]
  );

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = ["Print PDF", "Save PNG"];
  const handleSplitButtonClick = (index) => {
    if (![0, 1].includes(index)) return;

    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getMoreFiltersCount = (filterValues) => {
    const keys = [
      "isActive",
      "inDistrict",
      "isPermitted",
      "isMonitoring",
      "hasWaterLevels",
      "hasWqData",
      "hasProductionData",
      "hasGeoLog",
    ];
    return keys.filter((key) => filterValues[key].value).length;
  };

  const handleCategorize = (value) => {
    handleActiveStyle(value);
    const index = splitButtonOptionsFilters.findIndex(
      (item) => item?.name === value
    );
    setSelectedIndex(index);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}
      <FiltersBarRoot>
        <Search onSelect={handleSearchSelect} sources={sources} />

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="260px"
              label={`Categorize by ${activeStyle.name}`}
            >
              <Typography variant="subtitle1" gutterBottom>
                Categorize by
              </Typography>
              <WellStylesControl
                label="Color wells by"
                name="wellStyles"
                onChange={handleCategorize}
                options={styleOptions}
                value={activeStyle.id}
              />
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <ButtonGroup
              variant="contained"
              color="primary"
              ref={anchorRef}
              aria-label="split button"
              style={{
                marginLeft: theme.spacing(1),
              }}
            >
              <FilterControl
                width="265px"
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                appliedCount={
                  splitButtonOptionsFilters[selectedIndex].value?.length
                }
                countText={`${splitButtonOptionsFilters[selectedIndex].value?.length}/${splitButtonOptionsFilters[selectedIndex].options?.length}`}
                label={`Filter by ${splitButtonOptionsFilters[selectedIndex].label}`}
              >
                <Filter
                  label={splitButtonOptionsFilters[selectedIndex].label}
                  name={splitButtonOptionsFilters[selectedIndex].name}
                  onChange={handleFilterValues}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={splitButtonOptionsFilters[selectedIndex].options}
                  type={splitButtonOptionsFilters[selectedIndex].type}
                  value={splitButtonOptionsFilters[selectedIndex].value}
                />
              </FilterControl>

              <Button
                disableElevation
                color="primary"
                variant="outlined"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
              <Tooltip title="Reset All Filters" placement="bottom">
                <Button
                  disableElevation
                  color="primary"
                  variant="outlined"
                  onClick={handleResetAll}
                >
                  <RotateLeft />
                </Button>
              </Tooltip>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 4 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {splitButtonOptionsFilters.map((option, index) => (
                          <MenuItem
                            key={option.label}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {`${option.label} - ${splitButtonOptionsFilters[index].value?.length}/${splitButtonOptionsFilters[index].options?.length}`}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="100px"
              appliedCount={getMoreFiltersCount(filterValues)}
              label="Toggles"
            >
              <Box display="flex" flexDirection="column">
                <Filter
                  label="Is Active"
                  name="isActive"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.isActive?.value}
                />
                <Filter
                  label="In District"
                  name="inDistrict"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.inDistrict?.value}
                />
                <Filter
                  label="Is Permitted"
                  name="isPermitted"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.isPermitted?.value}
                />
                {/*{(currentUser?.isAdmin || currentUser?.isDeveloper) && (*/}
                {/*  <Filter*/}
                {/*    label="Is Index Well"*/}
                {/*    name="isIndexWell"*/}
                {/*    onChange={handleFilterValues}*/}
                {/*    type="boolean"*/}
                {/*    value={filterValues?.isIndexWell?.value}*/}
                {/*  />*/}
                {/*)}*/}
                <Filter
                  label="Is Monitoring"
                  name="isMonitoring"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.isMonitoring?.value}
                />
                <Filter
                  label="Has Water Levels Data"
                  name="hasWaterLevels"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasWaterLevels?.value}
                />
                <Filter
                  label="Was WQ Data"
                  name="hasWqData"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasWqData?.value}
                />
                <Filter
                  label="Has Production Data"
                  name="hasProductionData"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasProductionData?.value}
                />
                <Filter
                  label="Has Geo Log"
                  name="hasGeoLog"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasGeoLog?.value}
                />
              </Box>
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <>
              <SplitButton
                options={splitButtonOptions}
                handleExportClick={handleSplitButtonClick}
              />
              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>
      <Map ref={mapContainer}>
        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />
        {/*<AddressSearchControl*/}
        {/*  onSelect={(coordinates) =>*/}
        {/*    map?.flyTo({ center: coordinates, zoom: 16 })*/}
        {/*  }*/}
        {/*/>*/}
        {eventsRegistered && (
          <MainControl
            activeStyle={activeStyle}
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            layers={layers}
            onBasemapChange={updateBasemap}
            filters={filterValues}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            onBooleanChange={updateLayerBooleanFilter}
            value={filterValues?.search?.value}
            bufferValuesAdjacentWells={searchRadiusBuffersAdjacentWells}
            onBufferValuesChangeAdjacentWells={
              updateSearchRadiusBuffersAdjacentWells
            }
            onClearBuffersAdjacentWells={
              handleClearSearchRadiusBuffersAdjacentWells
            }
            onEnableSearchRadiusControlAdjacentWells={
              handleEnableSearchRadiusControlAdjacentWells
            }
            onResetBuffersAdjacentWells={resetSearchRadiusBuffersAdjacentWells}
            intersectWellsEnabled={intersectWellsEnabled}
            setIntersectWellsEnabled={setIntersectWellsEnabled}
            addBuffersToMap={addBuffersToMap}
            map={map}
            addBuffersToMapAdjacentWells={addBuffersToMapAdjacentWells}
          />
        )}
        {process.env.NODE_ENV === "development" && (
          <ZoomInfo zoomLevel={zoomLevel} />
        )}
        <LegendControl
          open={legendVisible}
          onToggle={() => setLegendVisible(!legendVisible)}
        />
        {legendVisible && <Legend />}
        <DataVizControl open={dataVizVisible} onClose={handleDataVizClose} />
        <DataViz
          open={dataVizVisible}
          dataVizWell={dataVizWell}
          dataVizGraphType={dataVizGraphType}
          onClose={handleDataVizClose}
          wells={wells}
        />
        {/*<VirtualBoreControl*/}
        {/*  open={virtualBoreVisible}*/}
        {/*  onClose={() => setVirtualBoreVisible(!virtualBoreVisible)}*/}
        {/*/>*/}
        {/*<VirtualBore*/}
        {/*  open={virtualBoreVisible}*/}
        {/*  coordinates={virtualBoreCoordinates}*/}
        {/*  setCoordinates={setVirtualBoreCoordinates}*/}
        {/*  onClose={() => setVirtualBoreVisible(false)}*/}
        {/*  map={map}*/}
        {/*/>*/}
        {/*eslint-disable-next-line*/}
        {!measurementsVisible && !isTouchScreenDevice() && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </Box>
  );
};

export default PublicMap;

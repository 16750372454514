import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const FileFormatSelect = ({
  control,
  fileFormat,
  fileFormats,
  isUploading,
  isFileFormatsLoading,
  errors,
  setValue,
}) => {
  return (
    <FormControl fullWidth disabled={isFileFormatsLoading || isUploading}>
      <InputLabel id="file-format-label">File Format</InputLabel>
      <Controller
        name="fileFormat"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            labelId="file-format-label"
            value={fileFormat || ""}
            onChange={(e) => setValue("fileFormat", e.target.value)}
          >
            {fileFormats.map((format) => (
              <MenuItem
                key={format.file_format_ndx}
                value={format.file_format_ndx}
              >
                {format.file_format_desc}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors.fileFormat && (
        <Box mt={1}>
          <Alert severity="error">{errors.fileFormat.message}</Alert>
        </Box>
      )}
    </FormControl>
  );
};

export default FileFormatSelect;

import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Paper,
  CircularProgress,
  Tooltip,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Button,
  StepContent,
} from "@material-ui/core";
import { FormField } from "../inputs/react-hook-form/FormField";
import styled from "styled-components";
import { useApp } from "../../../AppProvider";
import { FlexBox, GridItem, StyledSaveButton } from "../components/ui";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { Lock, Refresh } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import get from "lodash.get";

export const SectionContainer = styled(Paper)`
  width: 100%;
  padding: 8px;
`;

export const Header = styled(Typography)`
  margin-bottom: 16px;
  font-weight: bold;
`;

const StyledChip = styled(Chip)`
  white-space: nowrap;
  height: 20px;
  font-size: 0.7rem;
  background: ${(props) => props.theme.palette.background.default};

  .MuiChip-label {
    padding: 0 6px;
  }
`;

const HorizontalStepperContainer = styled(Box)`
  width: 100%;
`;

const StyledHorizontalStepper = styled(Stepper)`
  width: 100%;
  overflow: auto;
  padding: 8px 0;
`;

const StyledStep = styled(Step)`
  min-width: ${({ steps }) =>
    `${Math.min(120, 960 / (steps - 1))}px`}; /* Dynamic min-width */
`;

const Label = styled(Typography).withConfig({
  shouldForwardProp: (prop) => prop !== "isDirty" && prop !== "hasError",
})`
  font-weight: 600;
  border-left: ${({ theme, isDirty, hasError }) =>
    isDirty
      ? `4px solid ${
          hasError ? theme.palette.error.main : theme.palette.secondary.main
        }`
      : "4px solid transparent"};
  padding-left: 4px;
`;

const ResetButton = ({ onClick }) => (
  <Tooltip title="Discard changes" arrow>
    <IconButton onClick={onClick} size="small">
      <Refresh />
    </IconButton>
  </Tooltip>
);

const FieldLabel = ({ field, isDirty, hasError, infoTooltip }) => (
  <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
    <Label
      color={hasError ? "error" : "textPrimary"}
      isDirty={isDirty}
      hasError={hasError}
    >
      <Tooltip title={isDirty ? "Has unsaved changes" : ""}>
        <span>{field.label}</span>
      </Tooltip>
    </Label>
    {infoTooltip && (
      <Tooltip title={infoTooltip} arrow>
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    )}
  </Box>
);

const FormFieldWrapper = ({ field, formMethods, lookupOptions }) => {
  const { control, formState, resetField, setValue } = formMethods;
  const isDirty = !!get(formState.dirtyFields, field.name);
  const hasError = !!get(formState.errors, field.name);
  const isReadOnly = field?.readOnly;
  const infoTooltip = field?.infoTooltip;

  // Handle showIf logic
  const { fn: showIfFn, deps: showIfDeps } = field.showIf || {};
  const showIfValuesArray = useWatch({ control, name: showIfDeps || [] });
  const showIfValues = (showIfDeps || []).reduce((acc, key, index) => {
    acc[key] = showIfValuesArray[index] ?? null;
    return acc;
  }, {});
  const shouldShow = showIfFn ? showIfFn(showIfValues) : true;

  const prevShouldShow = useRef(shouldShow);
  useEffect(() => {
    if (prevShouldShow.current && !shouldShow) {
      setValue(field.name, field.showIf?.resetValue || null);
    }
    prevShouldShow.current = shouldShow;
  }, [shouldShow, field.name, setValue, field.showIf?.resetValue]);

  const isRequired =
    (field.validation && !field.validation.safeParse(undefined).success) ||
    (shouldShow && field.showIf?.required);

  if (!shouldShow) return null;

  // Handle map separately
  if (field.type === "map") {
    return (
      <Grid item xs={12}>
        <FormField field={field} />
      </Grid>
    );
  }

  return (
    <GridItem {...field?.props?.GridItem}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="8px"
      >
        <FieldLabel
          field={field}
          isDirty={isDirty}
          hasError={hasError}
          infoTooltip={infoTooltip}
        />
        {isRequired && <StyledChip label="Required" />}
      </Box>
      <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
        <FormField field={field} lookupOptions={lookupOptions} />
        {!isReadOnly && isDirty && (
          <ResetButton onClick={() => resetField(field.name)} />
        )}
        {isReadOnly && (
          <Tooltip title="This field cannot be edited" arrow>
            <Lock color="disabled" />
          </Tooltip>
        )}
      </Box>
    </GridItem>
  );
};

export const FormSection = ({ section, formMethods, lookupsData, isAdmin }) => (
  <SectionContainer key={section.title}>
    <Header variant="h6">{section.title}</Header>
    <Grid container spacing={4}>
      {section.fields.map(
        (field) =>
          (!field?.adminOnly || isAdmin) && (
            <FormFieldWrapper
              key={field.name}
              field={field}
              formMethods={formMethods}
              lookupOptions={getLookupOptions(field, lookupsData)}
            />
          )
      )}
    </Grid>
  </SectionContainer>
);

const VerticalFormStepper = ({
  stepNames,
  currentStepIndex,
  setCurrentStepIndex,
  children,
}) => {
  const stepRefs = useRef([]);

  useEffect(() => {
    const firstStep = stepRefs.current[0]; // First step for reference
    const currentStep = stepRefs.current[currentStepIndex];

    if (firstStep && currentStep) {
      const firstStepTop =
        firstStep.getBoundingClientRect().top + window.scrollY;
      const currentStepTop =
        currentStep.getBoundingClientRect().top + window.scrollY;

      const offset = currentStepTop - firstStepTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [currentStepIndex]);

  return (
    <Stepper
      orientation="vertical"
      activeStep={currentStepIndex}
      nonLinear
      style={{ padding: "8px" }}
    >
      {stepNames.map((step, index) => (
        <Step key={index} completed={index < currentStepIndex}>
          <StepLabel
            onClick={() => setCurrentStepIndex(index)}
            style={{ cursor: "pointer" }}
          >
            <div ref={(el) => (stepRefs.current[index] = el)}>{step}</div>
          </StepLabel>
          {index === currentStepIndex && (
            <StepContent style={{ paddingLeft: "4px", paddingRight: 0 }}>
              {children}
            </StepContent>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

const HorizontalFormStepper = ({
  stepNames,
  currentStepIndex,
  setCurrentStepIndex,
}) => {
  return (
    <HorizontalStepperContainer>
      <StyledHorizontalStepper
        activeStep={currentStepIndex}
        alternativeLabel
        nonLinear
      >
        {stepNames.map((step, index) => (
          <StyledStep
            steps={stepNames.length}
            key={index}
            completed={index < currentStepIndex}
          >
            <StepLabel
              style={{ cursor: "pointer" }}
              onClick={() => setCurrentStepIndex(index)}
            >
              {step}
            </StepLabel>
          </StyledStep>
        ))}
      </StyledHorizontalStepper>
    </HorizontalStepperContainer>
  );
};

const getLookupOptions = (field, lookupsData) =>
  field?.lookupTable?.tableName && lookupsData[field.lookupTable.tableName]
    ? lookupsData[field.lookupTable.tableName]
    : [];

const processSteps = (config) => {
  let lastStep = null;
  return config.map((section) => {
    if (section.step) lastStep = section.step;
    return { ...section, step: lastStep };
  });
};

export function DynamicForm({ config, formMethods, onSubmit, lookupsData }) {
  const {
    formState: { isDirty, isSubmitting },
  } = formMethods;
  const { currentUser, doToast } = useApp();
  const { isDownSm, isXs } = useBreakpoints();

  const isAdmin = currentUser?.isAdmin;
  const gap = isDownSm ? "12px" : "24px";
  const buttonSize = isXs ? "medium" : "large";

  const processedConfig = processSteps(config);

  const stepGroups = processedConfig.reduce((acc, section) => {
    acc[section.step] = acc[section.step] || [];
    acc[section.step].push(section);
    return acc;
  }, {});

  const stepNames = Object.keys(stepGroups);
  const hasSteps = stepNames.length > 1;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStepName = stepNames[currentStepIndex];

  const handleNext = () => {
    setCurrentStepIndex((prev) => prev + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setCurrentStepIndex((prev) => prev - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onInvalid = (errors) => {
    const errorCount = Object.keys(errors).length;

    const fieldLabelMap = config.reduce((acc, section) => {
      section.fields.forEach((field) => {
        acc[field.name] = field.label;
      });
      return acc;
    }, {});

    const errorMessages = Object.entries(errors).map(([fieldName, error]) => {
      const label = fieldLabelMap[fieldName] || fieldName;
      return `${label}: ${error.message}`;
    });

    const toastMessage = (
      <div>
        Please correct {errorCount} error(s) before submitting:
        <br />
        {errorMessages.map((message, index) => (
          <div key={index}>
            {index + 1}. {message}
          </div>
        ))}
      </div>
    );

    doToast("error", toastMessage, { persist: true });
    console.error("Validation errors:", errors);
  };

  // If there's only one step, no stepper
  if (!hasSteps) {
    return (
      <FormProvider {...formMethods}>
        <form style={{ width: "100%" }}>
          <FlexBox style={{ gap }}>
            {processedConfig.map(
              (section) =>
                (!section?.adminOnly || isAdmin) && (
                  <Box height="100%" width="100%" key={section.title}>
                    <FormSection
                      section={section}
                      formMethods={formMethods}
                      lookupsData={lookupsData}
                      isAdmin={isAdmin}
                    />
                  </Box>
                )
            )}
            <StyledSaveButton
              onClick={formMethods.handleSubmit(onSubmit, onInvalid)}
              variant="contained"
              color="primary"
              size={buttonSize}
              disabled={isSubmitting || !isDirty}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
            </StyledSaveButton>
          </FlexBox>
        </form>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: "100%" }}>
        {isDownSm ? (
          // SMALL SCREENS => VERTICAL
          <VerticalFormStepper
            stepNames={stepNames}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
          >
            <FlexBox style={{ gap }}>
              {stepGroups[currentStepName]?.map(
                (section) =>
                  (!section?.adminOnly || isAdmin) && (
                    <Box height="100%" width="100%" key={section.title}>
                      <FormSection
                        section={section}
                        formMethods={formMethods}
                        lookupsData={lookupsData}
                        isAdmin={isAdmin}
                      />
                    </Box>
                  )
              )}

              <Box display="flex" alignSelf="start" style={{ gap: "8px" }}>
                <Button disabled={currentStepIndex === 0} onClick={handleBack}>
                  Back
                </Button>

                <StyledSaveButton
                  onClick={
                    currentStepIndex < stepNames.length - 1
                      ? handleNext
                      : formMethods.handleSubmit(onSubmit, onInvalid)
                  }
                  style={{ width: "fit-content" }}
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  disabled={
                    isSubmitting ||
                    (currentStepIndex === stepNames.length - 1 && !isDirty)
                  }
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : currentStepIndex < stepNames.length - 1 ? (
                    "Next"
                  ) : (
                    "Submit"
                  )}
                </StyledSaveButton>
              </Box>
            </FlexBox>
          </VerticalFormStepper>
        ) : (
          // MEDIUM + SCREENS => HORIZONTAL
          <FlexBox style={{ gap }}>
            <HorizontalFormStepper
              stepNames={stepNames}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
            />
            {stepGroups[currentStepName]?.map(
              (section) =>
                (!section?.adminOnly || isAdmin) && (
                  <Box height="100%" width="100%" key={section.title}>
                    <FormSection
                      section={section}
                      formMethods={formMethods}
                      lookupsData={lookupsData}
                      isAdmin={isAdmin}
                    />
                  </Box>
                )
            )}

            <Button disabled={currentStepIndex === 0} onClick={handleBack}>
              Back
            </Button>
            <StyledSaveButton
              onClick={
                currentStepIndex < stepNames.length - 1
                  ? handleNext
                  : formMethods.handleSubmit(onSubmit, onInvalid)
              }
              variant="contained"
              color="primary"
              size={buttonSize}
              disabled={
                isSubmitting ||
                (currentStepIndex === stepNames.length - 1 && !isDirty)
              }
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : currentStepIndex < stepNames.length - 1 ? (
                "Next"
              ) : (
                "Submit"
              )}
            </StyledSaveButton>
          </FlexBox>
        )}
      </form>
    </FormProvider>
  );
}

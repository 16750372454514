import { useMutation } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";

export const useTriggerRebuildMapLayer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const mutation = useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/rebuild-map-wells-layer`,
        { headers }
      );
    },
    {
      onSuccess: () =>
        doToast("success", "Wells map layer refresh triggered successfully!"),
      onError: (error) => {
        const message =
          error?.response?.data?.message ||
          "Failed to refresh wells map layer. Please try again or contact LRE Water for assistance.";
        doToast("error", message);
      },
    }
  );

  return {
    triggerRebuild: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
  };
};

import React from "react";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { useHistory } from "react-router-dom";
import { useDynamicForm } from "../hooks/useDynamicForm";
import { DynamicForm } from "../forms/DynamicForm";
import { createWellFormConfig } from "../formConfigs/createWellFormConfig";

const ID = null;
const IS_NEW_RECORD = true;
const WELL_NDX = null;

const CreateWellPage = function () {
  const history = useHistory();

  const handleRedirect = ({ district_well_id }) => {
    const redirectId = district_well_id || ID;
    history.push(FieldServicesAppRoutes.WELL_ACTIONS(redirectId));
  };

  const { isLoading, error, refetchLookups, ...rest } = useDynamicForm({
    ndx: WELL_NDX,
    isNewRecord: IS_NEW_RECORD,
    config: createWellFormConfig,
    endpoints: {
      submit: "/create-well",
    },
    handleRedirect,
  });

  return (
    <PageRoot>
      <Helmet title="Create Well | Field Services" />
      <PageAppBar
        title={<strong>Create a New Well</strong>}
        buttonPath={
          IS_NEW_RECORD
            ? FieldServicesAppRoutes.HOME()
            : FieldServicesAppRoutes.WELL_ACTIONS(ID)
        }
        buttonLabel={IS_NEW_RECORD ? "Home" : "Well Actions"}
      />
      <PageContainer>
        {error ? (
          <ErrorCard
            title={
              IS_NEW_RECORD ? "Failed to Load" : `Failed to Load Well ${ID}`
            }
            onRetry={async () => {
              await refetchLookups();
            }}
          />
        ) : isLoading ? (
          <ContentSkeleton />
        ) : (
          <DynamicForm config={createWellFormConfig} {...rest} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default CreateWellPage;

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { LinearProgress } from "@mui/material";

const UploadProgress = ({ progress }) => (
  <Box mt={2} width="100%">
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Typography variant="caption">
        Progress: {Math.round(progress)}%
      </Typography>
    </Box>
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{ width: "100%" }}
    />
  </Box>
);

export default UploadProgress;

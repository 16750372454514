import React from "react";
import styled from "styled-components";
import { Box, Typography, Button } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/CloudUpload";

const StyledDropContainer = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== "isDragActive" && prop !== "hasError",
})`
  border: 2px dashed #eeeeee;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;

  ${({ isDragActive }) =>
    isDragActive &&
    `
      border-color: #2196f3;
      background-color: rgba(33, 150, 243, 0.1);
  `}

  ${({ hasError }) =>
    hasError &&
    `
      border-color: #f44336;
  `}
`;

const DragAndDropArea = ({
  isDragActive,
  getRootProps,
  getInputProps,
  isUploading,
  hasError,
  fileInputRef,
  handleFileInput,
  maxFileSizeMB,
  maxTotalSizeMB,
}) => {
  return (
    <>
      <StyledDropContainer
        {...getRootProps()}
        isDragActive={isDragActive}
        hasError={hasError}
      >
        <input {...getInputProps()} />
        <UploadIcon
          style={{
            fontSize: "48px",
            color: isDragActive ? "#2196f3" : "#bdbdbd",
          }}
        />
        <Typography variant="body1" style={{ marginTop: "8px" }}>
          {isDragActive
            ? "Drop the files here"
            : "Drag and drop files here, or click to select files"}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Only .txt files (max {maxFileSizeMB}MB each, {maxTotalSizeMB}MB total)
        </Typography>
      </StyledDropContainer>

      <Box mt={1} textAlign="center">
        <Button
          variant="contained"
          component="label"
          startIcon={<UploadIcon />}
          disabled={isUploading}
          fullWidth
          style={hasError ? { border: "2px solid red" } : {}}
        >
          Browse Files
          <input
            type="file"
            hidden
            accept=".txt"
            multiple
            ref={fileInputRef}
            onChange={handleFileInput}
          />
        </Button>
      </Box>
    </>
  );
};

export default DragAndDropArea;

import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useForm,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { FormProvider } from "react-hook-form";
import { FlexBox, StyledSaveButton } from "../components/ui";
import { FormSection } from "./DynamicForm";
import { useAxiosInstance } from "../hooks/useAxiosInstance";
import { ValidationSchemas } from "../formConfigs/formConstants";
import { useApp } from "../../../AppProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@material-ui/lab";
import { useBreakpoints } from "../hooks/useBreakpoints";

const ParameterRemoveButton = ({ onClick }) => (
  <Button variant="outlined" onClick={onClick} size="small">
    Remove
  </Button>
);

// Zod schema definitions
const ParameterSchema = z
  .object({
    parameter_ndx: ValidationSchemas.positiveInt,
    result_value: ValidationSchemas.number.nullable(),
    is_nondetect: ValidationSchemas.boolean,
  })
  .superRefine((row, ctx) => {
    if (
      row.is_nondetect &&
      (row.result_value === null || row.result_value === undefined)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["result_value"],
        message: "Enter detection limit as a value if result is a non-detect.",
      });
    }
  });

const WQFormSchema = z.object({
  collect_timestamp: ValidationSchemas.datetime,
  well_purged: ValidationSchemas.boolean,
  aqsci_data_source_ndx: ValidationSchemas.positiveInt,
  aqsci_lab_ndx: ValidationSchemas.positiveInt,
  notes: ValidationSchemas.string,
  sampled_by: ValidationSchemas.string,
  parameters: z.array(ParameterSchema),
  optional_param_ids: z.array(z.number()).optional(),
});

// Basic form fields
const generalFields = [
  {
    name: "collect_timestamp",
    label: "Collection Date/Time",
    type: "dateTime",
    validation: ValidationSchemas.datetime,
  },
  {
    name: "well_purged",
    label: "Well Purged?",
    type: "checkbox",
    validation: ValidationSchemas.boolean,
  },
  {
    name: "aqsci_data_source_ndx",
    label: "Source of Data",
    type: "selectSingle",
    lookupTable: { tableName: "wqDataSources" },
    validation: ValidationSchemas.positiveInt,
  },
  {
    name: "aqsci_lab_ndx",
    label: "Lab",
    type: "selectSingle",
    lookupTable: { tableName: "wqLabs" },
    validation: ValidationSchemas.positiveInt,
  },
  {
    name: "sampled_by",
    label: "Sampled By",
    type: "text",
    validation: ValidationSchemas.string,
    readOnly: true,
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
    validation: ValidationSchemas.string,
  },
];

// Additional optional parameters field
const optionalParamField = {
  name: "optional_param_ids",
  label: "Parameters",
  type: "selectMulti",
  lookupTable: { tableName: "wqParameters" },
  validation: z.array(ValidationSchemas.positiveInt).nullable().optional(),
  props: {
    GridItem: { sm: 12, md: 12, lg: 12 },
  },
};
const optionalParamSectionFields = [optionalParamField];

// Watches for toggling non-detect to re-trigger validation
const ParameterRowValidationWatcher = ({ index }) => {
  const { trigger, formState } = useFormContext();
  const isNonDetect = useWatch({
    name: `parameters.${index}.is_nondetect`,
  });

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`parameters.${index}.result_value`);
    }
  }, [isNonDetect, index, formState.isSubmitted, trigger]);

  return null;
};

export function WaterQualityForm({
  wellNdx,
  paramLookup,
  dataSourceLookup,
  labLookup,
  onSuccess,
  onError,
}) {
  const { user } = useAuth0();
  const { doToast } = useApp();
  const axiosInstance = useAxiosInstance();
  const { isDownSm, isXs } = useBreakpoints();

  const gap = isDownSm ? "12px" : "24px";
  const buttonSize = isXs ? "medium" : "large";

  const formMethods = useForm({
    mode: "onSubmit",
    resolver: zodResolver(WQFormSchema),
    defaultValues: {
      collect_timestamp: new Date().toISOString(),
      well_purged: true,
      aqsci_data_source_ndx: null,
      aqsci_lab_ndx: null,
      notes: "",
      sampled_by: user?.sub || "",
      parameters: [],
      optional_param_ids: [],
    },
  });

  const { control, handleSubmit, watch, formState, setValue, reset } =
    formMethods;
  const { isSubmitting, isDirty } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parameters",
  });

  const handleRemove = (index) => {
    const removedParamId = fields[index]?.parameter_ndx;
    remove(index);
    if (optionalParamIds.includes(removedParamId)) {
      setValue(
        "optional_param_ids",
        optionalParamIds.filter((id) => id !== removedParamId)
      );
    }
  };

  // Separate primary vs. optional parameters
  const primaryParams = paramLookup.filter((p) => p.dataentry_primary);
  const optionalParams = paramLookup.filter((p) => !p.dataentry_primary);

  // On mount, auto‐append primary parameters if not present
  const [didAppendPrimary, setDidAppendPrimary] = useState(false);
  useEffect(() => {
    if (!didAppendPrimary && primaryParams.length) {
      setDidAppendPrimary(true);
      const appendedFields = primaryParams
        .filter((p) => !fields.some((f) => f.parameter_ndx === p.id))
        .map((p) => ({
          parameter_ndx: p.id,
          result_value: null,
          is_nondetect: false,
          label: p.label,
        }));
      if (appendedFields.length > 0) {
        appendedFields.forEach((f) => append(f));
        const currentValues = formMethods.getValues();
        reset({
          ...currentValues,
          parameters: [...currentValues.parameters],
        });
      }
    }
  }, [didAppendPrimary, primaryParams, fields, append, reset, formMethods]);

  // Add/remove optional parameters as user changes selection
  const watchedOptionalParamIds = watch("optional_param_ids");
  const optionalParamIds = useMemo(
    () => watchedOptionalParamIds || [],
    [watchedOptionalParamIds]
  );

  useEffect(() => {
    let changed = false;

    optionalParamIds.forEach((pId) => {
      if (!fields.some((f) => f.parameter_ndx === pId)) {
        append({
          parameter_ndx: pId,
          result_value: null,
          is_nondetect: false,
          label: optionalParams.find((p) => p.id === pId)?.label,
        });
        changed = true;
      }
    });

    fields.forEach((row, idx) => {
      const isPrimary = primaryParams.some((pr) => pr.id === row.parameter_ndx);
      if (!isPrimary && !optionalParamIds.includes(row.parameter_ndx)) {
        remove(idx);
        changed = true;
      }
    });

    if (changed) {
      const currentValues = formMethods.getValues();
      reset({
        ...currentValues,
        parameters: [...currentValues.parameters],
        optional_param_ids: [...currentValues.optional_param_ids],
      });
    }
  }, [
    optionalParamIds,
    fields,
    append,
    remove,
    primaryParams,
    optionalParams,
    reset,
    formMethods,
  ]);

  async function onSubmit(formData) {
    if (!wellNdx) {
      onError?.("Invalid well or wellNdx not found.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `/well-actions/${wellNdx}/water-quality`,
        formData
      );
      reset();
      onSuccess?.(response.data);
    } catch (err) {
      console.error("Error submitting WaterQuality:", err);
      const msg =
        err?.response?.data?.message ||
        err.message ||
        "Failed to submit Water Quality.";
      onError?.(msg);
    }
  }

  function renderParameterRows() {
    return fields.map((fieldItem, index) => {
      const rowFields = [
        {
          name: `parameters.${index}.result_value`,
          label: "Result Value",
          type: "number",
          validation: ValidationSchemas.number,
          props: {
            GridItem: { xs: 6, md: 6, lg: 6 },
          },
        },
        {
          name: `parameters.${index}.is_nondetect`,
          label: "Non-Detect?",
          type: "checkbox",
          validation: ValidationSchemas.boolean,
          props: {
            GridItem: { xs: 6, md: 6, lg: 6 },
          },
        },
      ];

      const isPrimary = primaryParams.some(
        (p) => p.id === fieldItem.parameter_ndx
      );

      return (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={fieldItem.id}>
          <Paper style={{ position: "relative" }}>
            <FormSection
              section={{ title: fieldItem.label, fields: rowFields }}
              formMethods={formMethods}
            />
            <ParameterRowValidationWatcher index={index} />
            {!isPrimary && (
              <Box position="absolute" top={4} right={4} zIndex={1}>
                <ParameterRemoveButton onClick={() => handleRemove(index)} />
              </Box>
            )}
          </Paper>
        </Grid>
      );
    });
  }

  const allFieldDefinitions = [...generalFields, ...optionalParamSectionFields];
  const fieldLabelMap = allFieldDefinitions.reduce((acc, field) => {
    acc[field.name] = field.label;
    return acc;
  }, {});

  const parameterFieldLabelMap = {};
  fields.forEach((fieldItem, index) => {
    parameterFieldLabelMap[`parameters.${index}.result_value`] = "Result Value";
    parameterFieldLabelMap[`parameters.${index}.is_nondetect`] = "Non-Detect?";
  });

  const labelMap = {
    ...fieldLabelMap,
    ...parameterFieldLabelMap,
  };

  // For custom error messaging in a toast
  const onInvalid = (errors) => {
    const errorMessages = [];

    const collectErrors = (errObj, prefix = "") => {
      for (const key in errObj) {
        const val = errObj[key];
        const fieldPath = prefix ? `${prefix}.${key}` : key;
        if (val?.message) {
          const label = labelMap[fieldPath] || fieldPath;
          errorMessages.push(`${label}: ${val.message}`);
        } else if (typeof val === "object") {
          collectErrors(val, fieldPath);
        }
      }
    };

    collectErrors(errors);

    const toastMessage = (
      <div>
        Please correct {errorMessages.length} error(s) before submitting:
        <br />
        {errorMessages.map((message, idx) => (
          <div key={idx}>
            {idx + 1}. {message}
          </div>
        ))}
      </div>
    );

    doToast("error", toastMessage, { persist: true });
    console.error("Validation errors:", errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <FlexBox style={{ gap }}>
          <FormSection
            section={{ title: "General Information", fields: generalFields }}
            formMethods={formMethods}
            lookupsData={{
              wqDataSources: dataSourceLookup,
              wqLabs: labLookup,
            }}
          />

          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            border="2px solid #e0e0e0"
            borderRadius="4px"
            padding="12px 8px"
            bgcolor="#fafafa"
            style={{ gap: "8px" }}
          >
            <Typography
              variant="h5"
              style={{
                width: "100%",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "8px",
              }}
            >
              Parameters
            </Typography>
            <Alert
              severity="info"
              style={{ fontStyle: "italic", marginBottom: 8 }}
            >
              Enter detection limit as a value if result is a non-detect
            </Alert>
            {fields.length === 0 ? (
              <Box p={2}>No parameters yet.</Box>
            ) : (
              <Grid container spacing={2}>
                {renderParameterRows()}
              </Grid>
            )}
          </Box>

          <FormSection
            section={{
              title: "Additional Parameters",
              fields: optionalParamSectionFields,
            }}
            formMethods={formMethods}
            lookupsData={{
              wqParameters: optionalParams,
            }}
          />

          <StyledSaveButton
            variant="contained"
            color="primary"
            size={buttonSize}
            onClick={handleSubmit(onSubmit, onInvalid)}
            disabled={isSubmitting || !isDirty}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
          </StyledSaveButton>
        </FlexBox>
      </form>
    </FormProvider>
  );
}

import { lookupConfig, ValidationSchemas } from "./formConstants";
import { z } from "zod";

export const meterReadingFormConfig = [
  {
    title: "Meter Reading",
    fields: [
      {
        name: "prev_meter_reading",
        label: "Last Reading",
        type: "number",
        readOnly: true,
        validation: ValidationSchemas.readOnlyNullableNumber,
      },
      {
        name: "meter_reading",
        label: "New Reading",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "adjustment",
        label: "Adjustment",
        type: "number",
        validation: z.number().catch(0),
      },
      {
        name: "mr_adjustment_reason_ndx",
        label: "Adjustment Reason",
        type: "selectSingle",
        lookupTable: lookupConfig.adjustmentReason,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
        showIf: {
          fn: (values) => ![0, null].includes(values.adjustment),
          deps: ["adjustment"],
          resetValue: null,
          required: true,
        },
        refine: (data) => {
          if (
            data.adjustment > 0 &&
            (data.mr_adjustment_reason_ndx === null ||
              data.mr_adjustment_reason_ndx === undefined)
          ) {
            return "Adjustment Reason is required";
          }
          return undefined;
        },
      },
      {
        name: "mr_report_status_ndx",
        label: "Reporting Status",
        type: "selectSingle",
        lookupTable: lookupConfig.reportStatus,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "calculated_gallons",
        label: "Calculated Gallons",
        type: "number",
        readOnly: true,
        validation: ValidationSchemas.number.nullable().optional(),
      },
      {
        name: "notes",
        label: "Notes",
        type: "text",
        validation: ValidationSchemas.string.nullable().optional(),
      },
    ],
  },
];

import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { RulerControl } from "mapbox-gl-controls";
import styled, { ThemeProvider } from "styled-components/macro";
import debounce from "lodash.debounce";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import "mapbox-gl-controls/lib/controls.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import ReactDOM from "react-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";

import { create } from "jss";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import createTheme from "../../../../theme";
import { useApp } from "../../../../AppProvider";
import {
  BASEMAP_STYLES,
  LOCATIONS_LAYER,
} from "../../components/selectLocationMap/config";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import ToggleBasemapControl from "../../../../components/map/ToggleBasemapControl";
import Popup from "../../components/selectLocationMap/Popup";
import Search from "../../components/selectLocationMap/Search";
import LocationsControl from "../../../../components/map/LocationsControl";
import { useMapWells } from "../../hooks/useMapWells";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  locationsLabelsLayer,
  locationsLayer,
  managementZonesFill,
  managementZonesLine,
} from "../../../../utils/map";
import ZonesControl from "../../../../components/map/ZonesControl";
import MarkerControl from "../../../../components/map/MarkerControl";
import { useFormContext } from "react-hook-form";
import { DEFAULT_MAP_CENTER } from "../../../publicMap/constants";
import { ErrorCard } from "../../components/ui";
import Skeleton from "@material-ui/lab/Skeleton";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import {
  fetchElevation,
  isValidLatLon,
  parseNumberInput,
} from "../../lib/utils";
import { useDynamicWatches } from "../../../ReportsAndAnalysis/queryAndDownload/useQueryAndDownload";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const popupTheme = createTheme();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: 12px;
  }
`;

const MapWrapper = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MapContainer = styled(Box)`
  height: 325px;
  width: 100%;
  position: relative;
`;

const LocationSelectionGuide = () => {
  return (
    <StyledAlert severity="info" variant="filled">
      <AlertTitle>Instructions</AlertTitle>
      Click on the map to set coordinates and elevation
      <CloseButton size="small">
        <Close fontSize="small" />
      </CloseButton>
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  && {
    position: absolute;
    width: 85%;
    max-width: 400px;
    text-align: center;
    z-index: 1000;
  }
`;

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

const toggleLayerVisibility = (map, layers, isVisible) => {
  if (map && layers.every((layer) => map.getLayer(layer))) {
    layers.forEach((layer) =>
      map.setLayoutProperty(layer, "visibility", isVisible ? "visible" : "none")
    );
  }
};

const flyToCoords = (map, lon, lat) => {
  return new Promise((resolve) => {
    const mapHeight = map?.getContainer().clientHeight;
    const pixelOffset = 75;
    const offset = [0, mapHeight / 2 - pixelOffset];

    map.flyTo({
      center: [lon, lat],
      duration: 100,
      offset,
    });

    map.once("moveend", () => {
      resolve();
    });
  });
};

const VALUES_TO_WATCH = ["lat_dd", "lon_dd"];

const SelectCoordinatesMap = () => {
  const { currentUser } = useApp();
  const { setValue, reset, watch } = useFormContext();

  const [map, setMap] = useState();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [locationsVisible, setLocationsVisible] = useState(false);
  const [zonesVisible, setZonesVisible] = useState(false);
  const [markerEnabled, setMarkerEnabled] = useState(true);
  const [marker, setMarker] = useState(null);

  const [clickedCoords, setClickedCoords] = useState(null);

  const mapContainerRef = useRef(null);
  const markerEnabledRef = useRef(markerEnabled);
  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "100%",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const popupSizeRef = useRef(null);

  const watchedValues = useDynamicWatches(watch, VALUES_TO_WATCH);

  const { data, isLoading, isFetching, error, refetch } = useMapWells();

  useEffect(() => {
    markerEnabledRef.current = markerEnabled;
  }, [markerEnabled]);

  //create map and apply all controls
  useEffect(() => {
    if (!mapContainerRef.current || isLoading) return;

    const initialCenter =
      watchedValues["lat_dd"] && watchedValues["lon_dd"]
        ? [watchedValues["lon_dd"], watchedValues["lat_dd"]]
        : DEFAULT_MAP_CENTER;
    const initialZoom =
      watchedValues["lat_dd"] && watchedValues["lon_dd"] ? 16 : 8;

    const mapInstance = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/" + BASEMAP_STYLES[0].url,
      center: initialCenter,
      zoom: initialZoom,
    });

    const mapHeight = mapInstance?.getContainer().clientHeight;
    const pixelOffset = 75;
    const offset = mapHeight / 2 - pixelOffset;

    mapInstance.flyTo({
      center: initialCenter,
      zoom: initialZoom,
      offset: [0, offset],
      duration: 0,
    });

    const markerInstance = new mapboxgl.Marker({ color: "#ff0000" })
      .setLngLat(initialCenter)
      .addTo(mapInstance);

    markerInstance.getElement().style.visibility = "hidden";

    mapInstance.on("click", async (e) => {
      if (!markerEnabledRef.current) return;

      const { lng, lat } = e.lngLat;
      markerInstance.setLngLat([lng, lat]);

      markerInstance.getElement().style.visibility = "visible";

      await flyToCoords(mapInstance, lng, lat);

      setClickedCoords({ lng, lat });
    });

    setMarker(markerInstance);

    mapInstance?.addControl(new mapboxgl.FullscreenControl(), "top-left");
    mapInstance?.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "top-left"
    );
    mapInstance?.addControl(new ResetZoomControl(), "top-left");
    mapInstance.addControl(
      new mapboxgl.ScaleControl({ unit: "imperial" }),
      "bottom-left"
    );
    mapInstance.addControl(
      new RulerControl({
        units: "feet",
        labelFormat: (n) => `${n.toFixed(2)} ft`,
      }),
      "bottom-left"
    );
    BASEMAP_STYLES.forEach((layer) => {
      return mapInstance.addControl(
        new ToggleBasemapControl(layer.url, layer.icon),
        "bottom-right"
      );
    });

    mapInstance.on("load", () => {
      setMapIsLoaded(true);
      setMap(mapInstance);
    });

    return () => mapInstance.remove();
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map?.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      if (!map?.getSource("locations")) {
        map?.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                type: "Feature",
                id: location.well_ndx,
                properties: {
                  ...location,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });

        if (!map?.getLayer("locations")) {
          map?.addLayer({
            ...locationsLayer,
            layout: {
              visibility: "none",
            },
          });

          map?.addLayer({
            ...locationsLabelsLayer,
            layout: {
              visibility: "none",
            },
          });
        }
      }

      if (!map?.getSource("management-zones")) {
        map?.addSource("management-zones", {
          type: "vector",
          url: "mapbox://bseacd.3s98gn4n",
        });

        map?.addLayer(managementZonesFill);
        map?.addLayer(managementZonesLine);
      }

      map?.on("click", "locations", (e) => {
        map?.fire("closeAllPopups");

        if (!e.features.length) return;

        const features = map?.queryRenderedFeatures(e.point);

        const coordinates = [e.lngLat.lng, e.lngLat.lat];

        const popupLayerIds = ["locations", "management-zones-fill"];

        const myFeatures = features.filter((feature) =>
          popupLayerIds.includes(feature?.layer?.id)
        );

        if (myFeatures.length > 0) {
          const popupNode = document.createElement("div");
          ReactDOM.render(
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={popupTheme}>
                <ThemeProvider theme={popupTheme}>
                  <Popup
                    layers={[LOCATIONS_LAYER, managementZonesFill]}
                    features={myFeatures}
                    currentUser={currentUser}
                    sizeRef={popupSizeRef}
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
      });

      map?.on("mouseenter", "locations", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      map?.on("mouseleave", "locations", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }, [mapIsLoaded, map, data]); //eslint-disable-line

  useEffect(() => {
    if (!map || !marker || !clickedCoords) return;

    const updateLocation = async () => {
      const { lat, lng } = clickedCoords;

      setValue("lon_dd", parseNumberInput(lng.toFixed(6)), {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("lat_dd", parseNumberInput(lat.toFixed(6)), {
        shouldDirty: true,
        shouldValidate: true,
      });

      fetchElevation(lat, lng).then((elevation) => {
        setValue("elevation_ft", parseNumberInput(elevation), {
          shouldDirty: true,
          shouldValidate: true,
        });
      });
    };

    updateLocation();
  }, [clickedCoords, reset, map, marker, setValue]);

  useEffect(() => {
    if (!map || !marker) return;

    const lat = watchedValues["lat_dd"];
    const lon = watchedValues["lon_dd"];
    const { isValid } = isValidLatLon(lat, lon);
    const mapHeight = map?.getContainer().clientHeight;
    const pixelOffset = 75;
    const offset = mapHeight / 2 - pixelOffset;

    if (isValid) {
      marker.getElement().style.visibility = "visible";
      marker.setLngLat([lon, lat]);

      map?.flyTo({
        center: [lon, lat],
        duration: 0,
        offset: [0, offset],
      });
    } else {
      setInstructionsOpen(true);
      marker.getElement().style.visibility = "hidden";

      map?.flyTo({
        center: DEFAULT_MAP_CENTER,
        zoom: 8,
        duration: 0,
        offset: [0, offset],
      });
    }
  }, [watchedValues, map, marker]);

  useEffect(() => {
    toggleLayerVisibility(
      map,
      ["locations-labels", "locations"],
      locationsVisible
    );
  }, [map, locationsVisible]);

  useEffect(() => {
    toggleLayerVisibility(
      map,
      ["management-zones-fill", "management-zones-line"],
      zonesVisible
    );
  }, [map, zonesVisible]);

  const handleSearchSelect = useCallback(
    (location) => {
      if (!location || !map) return;
      const targetCoords = location.location_geometry.coordinates;

      const mapHeight = map?.getContainer().clientHeight;

      const pixelOffset = 75;
      const offset = mapHeight / 2 - pixelOffset;

      map?.flyTo({
        center: targetCoords,
        zoom: 16,
        speed: 2.4,
        curve: 0.75,
        offset: [0, offset],
      });
    },
    [map]
  );

  return (
    <ErrorBoundary error={error} isFetching={isFetching} onRetry={refetch}>
      <Accordion
        defaultExpanded
        style={{
          border: "solid 1px rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 600 }}>
            Select Coordinates & Elevation Map
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Root>
            <Search onSelect={handleSearchSelect} data={data} />

            <MapWrapper
              component="section"
              onClick={() => instructionsOpen && setInstructionsOpen(false)}
            >
              <MapContainer ref={mapContainerRef} id="map" tabIndex={-1} />

              <LocationsControl
                open={locationsVisible}
                onToggle={() => setLocationsVisible((prev) => !prev)}
                top={10}
              />

              <ZonesControl
                open={zonesVisible}
                onToggle={() => setZonesVisible((prev) => !prev)}
                top={50}
              />

              <MarkerControl
                open={markerEnabled}
                onToggle={() => setMarkerEnabled((prev) => !prev)}
                top={90}
                title="Marker Placement"
              />
              {instructionsOpen && <LocationSelectionGuide />}
            </MapWrapper>
          </Root>
        </AccordionDetails>
      </Accordion>
    </ErrorBoundary>
  );
};

const ErrorBoundary = ({ error, isFetching, onRetry, children }) => {
  const { isDownSm } = useBreakpoints();
  const gap = isDownSm ? "12px" : "24px";

  if (error) return <ErrorCard {...{ error, isFetching, onRetry }} />;
  if (isFetching) return <MapLoadingSkeleton gap={gap} />;
  return children;
};

const MapLoadingSkeleton = ({ gap }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    style={{ gap }}
    mt="8px"
  >
    <Skeleton
      variant="text"
      width="240px"
      height="56px"
      style={{ maxWidth: "100%", alignSelf: "start" }}
    />
    <Skeleton
      variant="rect"
      width="300px"
      height="56px"
      style={{ maxWidth: "100%" }}
    />
    <Skeleton variant="rect" width="100%" height="325px" />
  </Box>
);

export default memo(SelectCoordinatesMap);

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useQuery } from "react-query";

export const useFileFormatsQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(
    "fileFormats",
    async () => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/upload-logger-data/file-formats`,
        { headers }
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

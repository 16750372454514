import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import { useQueries } from "react-query";
import MaterialTable from "material-table";
import {
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  Button,
  Box,
  Link,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import { Alert } from "@material-ui/lab";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const BASE_URL = process.env.REACT_APP_ENDPOINT;
const QUERY_CONFIGS = [
  {
    queryKey: "ui-cq-pumpage-calendar-crosstab",
    url: `${BASE_URL}/api/ui-cq-pumpage-calendar-crosstab`,
  },
  {
    queryKey: "ui-cq-pumpage-fiscal-crosstab",
    url: `${BASE_URL}/api/ui-cq-pumpage-fiscal-crosstab`,
  },
  {
    queryKey: "aquiferGroups",
    url: `${BASE_URL}/api/ui-lookup-list-aquifer-group`,
  },
  {
    queryKey: "aquifers",
    url: `${BASE_URL}/api/ui-lookup-list-aquifer`,
  },
];

const EXCLUDE_PERMIT_TYPE_LIST = ["NO CURRENT PERMIT", "Unknown"];

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const FISCAL_NAMES = [
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
];

const tableOptions = {
  emptyRowsWhenPaging: false,
  exportAllData: true,
  columnsButton: true,
  exportButton: { csv: true },
  pageSize: 30,
  pageSizeOptions: [5, 10, 30, 60],
  padding: "dense",
  searchFieldAlignment: "left",
  showTitle: false,
  maxBodyHeight: "600px",
  filtering: true,
  headerStyle: {
    whiteSpace: "nowrap",
    fontWeight: 600,
  },
  cellStyle: {
    whiteSpace: "nowrap",
  },
};

function useDebounce(callback, delay) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback(
    (...args) => {
      if (callbackRef.current.timeoutId) {
        clearTimeout(callbackRef.current.timeoutId);
      }
      callbackRef.current.timeoutId = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    },
    [delay]
  );
}

function getOrderedMonths(yearOption) {
  return yearOption === "calendar" ? MONTH_NAMES : FISCAL_NAMES;
}

function formatNumber(value) {
  return value != null ? Number(value).toLocaleString() : "";
}

const MultiSelect = ({ label, options, selected, onChange, getLabel }) => {
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleToggleAll = () => {
    if (isAllSelected) {
      onChange([]);
    } else {
      onChange([...options]);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "selectAll") {
      handleToggleAll();
    } else {
      onChange(value);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.map(getLabel).join(", ")}
      >
        {options.length > 0 && (
          <MenuItem
            value="selectAll"
            onClick={handleToggleAll}
            style={{
              fontWeight: 600,
              padding: "16px",
              justifyContent: "center",
              color: "#17A5B9",
              gap: "6px",
            }}
          >
            <span
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                border: "2px solid #17A5B9",
                color: "#17A5B9",
              }}
            >
              {isAllSelected ? <MinusIcon /> : <PlusIcon />}
            </span>
            {isAllSelected ? "Deselect All" : "Select All"}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={getLabel(option)} value={option}>
            <Checkbox
              checked={selected.some(
                (item) => getLabel(item) === getLabel(option)
              )}
            />
            <ListItemText primary={getLabel(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function PumpageReport() {
  const { getAccessTokenSilently } = useAuth0();

  const results = useQueries(
    QUERY_CONFIGS.map((config) => ({
      queryKey: config.queryKey,
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(config.url, { headers });
        return data;
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }))
  );

  // Extracting data, loading, and error states from results
  const [
    { data: calendarData = [] },
    { data: fiscalData = [] },
    { data: aquiferGroups = [] },
    { data: aquifers = [] },
  ] = results;

  const hasError = results.some((result) => result.error);
  const isLoading = results.some((result) => result.isLoading);

  const [filters, setFilters] = useState({
    reportYearOption: "calendar",
    startYear: 2008,
    endYear: new Date().getFullYear(),

    aquiferGroupList: [],
    aquiferList: [],
    mgmtZoneList: [],
    permitTypeList: [],
    permitUseList: [],
    permitStatusList: [],

    permitteeInfo: "",
  });

  // debounce permittee info
  const [permitteeDraft, setPermitteeDraft] = useState("");

  // only set default filters once
  const [filtersInitialized, setFiltersInitialized] = useState(false);

  // Combine the two datasets and build lookups
  const combinedData = useMemo(() => {
    return [...calendarData, ...fiscalData];
  }, [calendarData, fiscalData]);

  const lookupValues = useMemo(() => {
    const sortAlpha = (a, b) => String(a).localeCompare(String(b));

    // Get aquifer groups from API
    const aquiferGroupList = aquiferGroups;

    // Get aquifers from API
    const aquiferList = aquifers;

    // Rest of the lookups from combined data
    const mgmtZoneSet = new Set();
    const permitTypeSet = new Set();
    const permitUseSet = new Set();
    const permitStatusSet = new Set();

    let hasNullMgmtZone = false;
    let hasNullPermitType = false;
    let hasNullPermitUse = false;
    let hasNullPermitStatus = false;

    for (const row of combinedData) {
      if (row.mgmtzone_name) mgmtZoneSet.add(row.mgmtzone_name);
      else hasNullMgmtZone = true;

      if (row.permit_type_group) permitTypeSet.add(row.permit_type_group);
      else hasNullPermitType = true;

      if (Array.isArray(row.permit_use) && row.permit_use.length) {
        row.permit_use.forEach((use) => permitUseSet.add(use));
      } else {
        hasNullPermitUse = true;
      }

      if (row.permit_status) permitStatusSet.add(row.permit_status);
      else hasNullPermitStatus = true;
    }

    // Convert sets to sorted arrays and add "<blank>" as the last element if needed
    const sortedMgmtZoneList = Array.from(mgmtZoneSet).sort(sortAlpha);
    const sortedPermitTypeList = Array.from(permitTypeSet).sort(sortAlpha);
    const sortedPermitUseList = Array.from(permitUseSet).sort(sortAlpha);
    const sortedPermitStatusList = Array.from(permitStatusSet).sort(sortAlpha);

    if (hasNullMgmtZone) sortedMgmtZoneList.push("<blank>");
    if (hasNullPermitType) sortedPermitTypeList.push("<blank>");
    if (hasNullPermitUse) sortedPermitUseList.push("<blank>");
    if (hasNullPermitStatus) sortedPermitStatusList.push("<blank>");

    return {
      aquiferGroupList,
      aquiferList,
      mgmtZoneList: sortedMgmtZoneList,
      permitTypeList: sortedPermitTypeList,
      permitUseList: sortedPermitUseList,
      permitStatusList: sortedPermitStatusList,
    };
  }, [aquiferGroups, aquifers, combinedData]);

  // Initialize filters with "all" possible values only once
  useEffect(() => {
    if (
      !filtersInitialized &&
      lookupValues.aquiferGroupList.length > 0 &&
      lookupValues.aquiferList.length > 0 &&
      combinedData.length
    ) {
      setFilters((prev) => ({
        ...prev,
        aquiferGroupList: lookupValues.aquiferGroupList.filter(
          (item) => item !== "<blank>"
        ),
        aquiferList: lookupValues.aquiferList.filter(
          (item) => item !== "<blank>"
        ),
        mgmtZoneList: lookupValues.mgmtZoneList.filter(
          (item) => item !== "<blank>"
        ),
        permitTypeList: lookupValues.permitTypeList
          .filter((pt) => !EXCLUDE_PERMIT_TYPE_LIST.includes(pt))
          .filter((item) => item !== "<blank>"),
        permitUseList: lookupValues.permitUseList.filter(
          (item) => item !== "<blank>"
        ),
        permitStatusList: lookupValues.permitStatusList.filter(
          (item) => item !== "<blank>"
        ),
      }));
      setFiltersInitialized(true);
    }
  }, [filtersInitialized, lookupValues, combinedData]);

  const debouncedSetPermitteeInfo = useDebounce((value) => {
    setFilters((prev) => ({ ...prev, permitteeInfo: value }));
  }, 200);

  const handlePermitteeChange = useCallback(
    (e) => {
      const { value } = e.target;
      setPermitteeDraft(value);
      debouncedSetPermitteeInfo(value);
    },
    [debouncedSetPermitteeInfo]
  );

  const columns = useMemo(() => {
    const yearCol = {
      title:
        filters.reportYearOption === "calendar"
          ? "Calendar Year"
          : "Fiscal Year",
      field: filters.reportYearOption === "calendar" ? "c_year" : "fiscal_year",
      cellStyle: { whiteSpace: "nowrap" },
    };

    const orderedMonths = getOrderedMonths(filters.reportYearOption);

    const monthCols = orderedMonths.map((month) => ({
      title: month,
      field: `${month.toLowerCase().substring(0, 3)}_gallons`,
      render: (row) =>
        formatNumber(row[`${month.toLowerCase().substring(0, 3)}_gallons`]),
      cellStyle: { whiteSpace: "nowrap" },
      filtering: false,
    }));

    const additionalCols = [
      {
        title: "Total",
        field: "tot_gallons",
        render: (row) => formatNumber(row.tot_gallons),
        cellStyle: { whiteSpace: "nowrap" },
        filtering: false,
      },
      {
        title: "Aquifer Group",
        field: "aquifer_group_name",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Aquifer",
        field: "aquifer_name",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Management Zone",
        field: "mgmtzone_name",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Permit Type Group",
        field: "permit_type_group",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Permit Use",
        field: "permit_use",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Permit Status",
        field: "permit_status",
        cellStyle: { whiteSpace: "nowrap" },
      },
      {
        title: "Permittee Info",
        field: "permittee_info",
        cellStyle: { whiteSpace: "nowrap" },
      },
    ];

    return [
      {
        title: "District Well ID",
        field: "district_well_id",
        cellStyle: { whiteSpace: "nowrap" },
      },
      yearCol,
      ...monthCols,
      ...additionalCols,
    ];
  }, [filters]);

  // Filter the data based on the selected filters
  const displayedData = useMemo(() => {
    const rawData =
      filters.reportYearOption === "calendar" ? calendarData : fiscalData;
    if (!rawData.length) return [];

    const {
      startYear,
      endYear,
      aquiferGroupList,
      aquiferList,
      mgmtZoneList,
      permitTypeList,
      permitUseList,
      permitStatusList,
      permitteeInfo,
    } = filters;

    return rawData.filter((row) => {
      const year =
        filters.reportYearOption === "calendar" ? row.c_year : row.fiscal_year;
      if (typeof year !== "number" || year < startYear || year > endYear) {
        return false;
      }

      const checkNull = (value, list) => {
        if (value === null) return list.includes("<blank>");
        return list.includes(value);
      };

      if (
        !checkNull(
          row.aquifer_group_name,
          aquiferGroupList.map(({ aquifer_group_name }) => aquifer_group_name)
        )
      )
        return false;
      if (
        !checkNull(
          row.aquifer_name,
          aquiferList.map(({ aquifer_name }) => aquifer_name)
        )
      )
        return false;
      if (!checkNull(row.mgmtzone_name, mgmtZoneList)) return false;
      if (!checkNull(row.permit_type_group, permitTypeList)) return false;
      if (!checkNull(row.permit_status, permitStatusList)) return false;
      if (
        !permitUseList.some(
          (use) =>
            row.permit_use?.includes(use) ||
            (row.permit_use === null && use === "<blank>")
        )
      )
        return false;

      return !(
        permitteeInfo &&
        !(row.permittee_info || "")
          .toLowerCase()
          .includes(permitteeInfo.toLowerCase())
      );
    });
  }, [filters, calendarData, fiscalData]);

  const updateFilter = useCallback((field, value) => {
    setFilters((prev) => {
      let newFilters = { ...prev, [field]: value };

      // If updating the aquifer group list, clean the aquifer list
      if (field === "aquiferGroupList") {
        const selectedGroupIds = value.map((group) => group.aquifer_group_ndx);

        // Filter the aquifer list to only include aquifers from the selected groups
        const filteredAquifers = prev.aquiferList.filter((aquifer) =>
          selectedGroupIds.includes(aquifer.aquifer_group_ndx)
        );

        newFilters.aquiferList = filteredAquifers;
      }

      return newFilters;
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters({
      reportYearOption: "calendar",
      startYear: 2008,
      endYear: new Date().getFullYear(),
      aquiferGroupList: lookupValues.aquiferGroupList,
      aquiferList: lookupValues.aquiferList,
      mgmtZoneList: lookupValues.mgmtZoneList,
      permitTypeList: lookupValues.permitTypeList,
      permitUseList: lookupValues.permitUseList,
      permitStatusList: lookupValues.permitStatusList,
      permitteeInfo: "",
    });
    setPermitteeDraft("");
  }, [lookupValues]);

  if (hasError) return "An error has occurred. Please try again.";
  return (
    <>
      <Helmet title="Pumpage Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Pumpage Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Pumpage</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper style={{ padding: "10px", marginBottom: "20px" }}>
        <Grid container spacing={3}>
          {/* Report Year Option */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Report Year Option</InputLabel>
              <Select
                label="Report Year Option"
                value={filters.reportYearOption}
                onChange={(e) =>
                  updateFilter("reportYearOption", e.target.value)
                }
              >
                <MenuItem value="calendar">Calendar Year</MenuItem>
                <MenuItem value="fiscal">Fiscal Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Year range */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="From Year"
              type="number"
              variant="outlined"
              fullWidth
              value={filters.startYear}
              onChange={(e) =>
                updateFilter("startYear", Number(e.target.value))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="To Year"
              type="number"
              variant="outlined"
              fullWidth
              value={filters.endYear}
              onChange={(e) => updateFilter("endYear", Number(e.target.value))}
            />
          </Grid>

          {/* Aquifer Group multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Aquifer Group"
              options={lookupValues.aquiferGroupList}
              selected={filters.aquiferGroupList}
              onChange={(value) => updateFilter("aquiferGroupList", value)}
              getLabel={(item) => item.aquifer_group_name}
            />
          </Grid>

          {/* Aquifer multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Aquifer"
              options={lookupValues.aquiferList.filter((aquifer) =>
                filters.aquiferGroupList
                  .map((group) => group.aquifer_group_ndx)
                  .includes(aquifer.aquifer_group_ndx)
              )}
              selected={filters.aquiferList}
              onChange={(value) => updateFilter("aquiferList", value)}
              getLabel={(item) => item.aquifer_name}
            />
          </Grid>

          {/* Management Zone multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Management Zone"
              options={lookupValues.mgmtZoneList}
              selected={filters.mgmtZoneList}
              onChange={(value) => updateFilter("mgmtZoneList", value)}
              getLabel={(item) => item}
            />
          </Grid>

          {/* Permit Type multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Permit Type"
              options={lookupValues.permitTypeList}
              selected={filters.permitTypeList}
              onChange={(value) => updateFilter("permitTypeList", value)}
              getLabel={(item) => item}
            />
          </Grid>

          {/* Permit Use multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Permit Use"
              options={lookupValues.permitUseList}
              selected={filters.permitUseList}
              onChange={(value) => updateFilter("permitUseList", value)}
              getLabel={(item) => item}
            />
          </Grid>

          {/* Permit Status multi-select */}
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label="Permit Status"
              options={lookupValues.permitStatusList}
              selected={filters.permitStatusList}
              onChange={(value) => updateFilter("permitStatusList", value)}
              getLabel={(item) => item}
            />
          </Grid>

          {/* Permittee (debounced) */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="Permittee"
              fullWidth
              value={permitteeDraft}
              onChange={handlePermitteeChange}
            />
          </Grid>
        </Grid>

        <Box my={2} textAlign="right">
          <Button variant="contained" onClick={handleResetFilters}>
            Reset Filters
          </Button>
        </Box>
      </Paper>
      <Paper style={{ padding: "10px" }}>
        {!displayedData?.length > 0 ? (
          <Alert severity="info">
            <Typography
              variant="h6"
              component="div"
              style={{ padding: "16px" }}
            >
              No records available for these search parameters. Please try
              again.
            </Typography>
          </Alert>
        ) : (
          <MaterialTable
            id="Pumpage Report"
            title="Pumpage Report"
            columns={columns}
            isLoading={isLoading}
            data={displayedData}
            options={tableOptions}
          />
        )}
      </Paper>
    </>
  );
}

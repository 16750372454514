import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const InstructionsAlert = () => (
  <Alert severity="info" style={{ marginBottom: "16px" }}>
    <AlertTitle>Instructions</AlertTitle>
    Please provide a <strong>brief description</strong>, your{" "}
    <strong>name</strong>, select the <strong>file format</strong>, and upload{" "}
    <strong>one or more .txt files</strong>. To select multiple files at once,
    either drag and drop them, or click “browse” and hold down{" "}
    <strong>Ctrl</strong> (Windows) or <strong>Cmd</strong> (Mac) while
    selecting. The system automatically processes all selected files at once.
    <br />
    <br />
    <strong>Note:</strong> Depending on the size/number of files, the upload
    process may take some time. You can navigate away from this page while it
    runs.
  </Alert>
);

export default InstructionsAlert;

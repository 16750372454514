import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const ConfirmationDialog = ({ open, files, onConfirm, onCancel }) => {
  const totalSizeMB =
    files.reduce((sum, file) => sum + file.size, 0) / 1024 / 1024;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm Upload</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to upload <strong>{files.length}</strong> file
          {files.length > 1 ? "s" : ""} (Total:{" "}
          <strong>{totalSizeMB.toFixed(2)} MB</strong>):
        </DialogContentText>
        {files.map((file, idx) => (
          <Box
            key={idx}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            mt={2}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
            bgcolor="#f9f9f9"
          >
            <Typography variant="body2" style={{ wordBreak: "break-word" }}>
              <strong>{file.name}</strong> – {(file.size / 1024).toFixed(2)} KB
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  PageRoot,
  PageAppBar,
  PageContainer,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { Grid, Typography, Paper, TextField } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

import { useQuery } from "react-query";
import { useAxiosInstance } from "../hooks/useAxiosInstance";
import { useDynamicForm } from "../hooks/useDynamicForm";

import SimpleSelect from "../inputs/SimpleSelect";
import { DynamicForm } from "../forms/DynamicForm";
import { meterReadingFormConfig } from "../formConfigs/meterReadingEntryFormConfig";

import {
  FieldServicesAppRoutes,
  USE_QUERY_CACHE_OPTIONS,
  USE_QUERY_OPTIONS,
} from "../lib/constants";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const SectionContainer = styled(Paper)`
  width: 100%;
  padding: 8px;
`;

const Header = styled(Typography)`
  margin-bottom: 16px;
  font-weight: bold;
`;

const IS_NEW_RECORD = false;

export default function MeterReadingPage() {
  const history = useHistory();
  const axiosInstance = useAxiosInstance();

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedPermitGroup, setSelectedPermitGroup] = useState("");
  const [selectedMeter, setSelectedMeter] = useState("");
  const [selectedMeterEntry, setSelectedMeterEntry] = useState("");

  // Fetch lookups for year, month, permit group
  const {
    data: yearsLookup = [],
    isLoading: isLoadingYears,
    error: errorYears,
    refetch: refetchYears,
  } = useQuery(
    "yearLookup",
    async () => {
      const { data } = await axiosInstance.get(
        "/lookup/ui-lookup-mr-filters-years-new"
      );
      return data || [];
    },
    {
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
    }
  );

  const {
    data: monthsLookup = [],
    isLoading: isLoadingMonths,
    error: errorMonths,
    refetch: refetchMonths,
  } = useQuery(
    "monthsLookup",
    async () => {
      const { data } = await axiosInstance.get("/lookup/list-months");
      return data || [];
    },
    {
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
    }
  );

  const {
    data: permitGroups = [],
    isLoading: isLoadingPermits,
    error: errorPermits,
    refetch: refetchPermits,
  } = useQuery(
    "permitGroupsLookup",
    async () => {
      const { data } = await axiosInstance.get(
        "/lookup/ui-lookup-mr-filters-permitgroupings"
      );
      return data || [];
    },
    {
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
      // Filter permit groups by selected year
      select: (data) =>
        data
          .filter((pg) => pg.fiscal_year === Number(selectedYear))
          .map((pg) => ({
            id: pg.grouping_ndx,
            label: pg.grouping_name,
          })),
    }
  );

  // Fetch meter records once year, month, permit are chosen
  const {
    data: meterRecords = [],
    error: errorMeterRecords,
    refetch: refetchMeterRecords,
  } = useQuery(
    ["meterRecords", selectedYear, selectedMonth, selectedPermitGroup],
    async () => {
      const { data } = await axiosInstance.get(
        `/meter-reading/meters/${selectedYear}/${selectedMonth}/${selectedPermitGroup}`
      );
      return data || [];
    },
    {
      ...USE_QUERY_OPTIONS,
      enabled: Boolean(selectedYear && selectedMonth && selectedPermitGroup),
      // Clean selected meter and meter entry if no applicable records found
      onSuccess: (fetchedRecords = []) => {
        const found =
          fetchedRecords.find((rec) => rec.meter_ndx === selectedMeter) ||
          fetchedRecords[0];
        setSelectedMeter(found?.meter_ndx || "");
        setSelectedMeterEntry(found?.meter_reading_ndx || "");
      },
    }
  );

  const redirectPath = FieldServicesAppRoutes.HOME();
  const handleRedirect = () => history.push(redirectPath);

  // Handle fetching meter reading form data and submitting
  const {
    isLoading: isLoadingFormData,
    error: formDataError,
    refetchFormData,
    refetchLookups,
    ...dynamicFormMethods
  } = useDynamicForm({
    ndx: selectedMeterEntry,
    isNewRecord: IS_NEW_RECORD,
    config: meterReadingFormConfig,
    endpoints: {
      fetch: "/meter-reading",
      submit: "/meter-reading",
    },
    handleRedirect,
  });

  const anyError =
    errorYears ||
    errorMonths ||
    errorPermits ||
    errorMeterRecords ||
    formDataError;

  const isLoadingLookups =
    isLoadingYears || isLoadingMonths || isLoadingPermits;

  return (
    <PageRoot>
      <Helmet title="Enter Meter Reading" />
      <PageAppBar
        title={<strong>Enter a Meter Reading</strong>}
        buttonPath={redirectPath}
        buttonLabel="Home"
      />
      <PageContainer style={{ gap: "12px" }}>
        {anyError ? (
          <ErrorCard
            onRetry={async () => {
              await Promise.all([
                refetchLookups(),
                selectedMeterEntry && refetchFormData(),
                refetchYears(),
                refetchMonths(),
                refetchPermits(),
                selectedPermitGroup && refetchMeterRecords(),
              ]);
            }}
          />
        ) : isLoadingLookups ? (
          <Grid container spacing={4}>
            {[...Array(4)].map((_, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <Skeleton variant="rect" height={36} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <SectionContainer>
              <Header variant="h6">Make Your Selections</Header>
              <Grid container spacing={4}>
                {/* Year */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SimpleSelect
                    label="Fiscal Year"
                    value={selectedYear || ""}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    options={yearsLookup.map((y) => ({
                      id: y.fiscal_year,
                      label: String(y.fiscal_year),
                    }))}
                  />
                </Grid>

                {/* Month */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SimpleSelect
                    label="Month"
                    value={selectedMonth || ""}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    options={monthsLookup.map((m) => ({
                      id: m.month_number,
                      label: m.month_name,
                    }))}
                  />
                </Grid>

                {/* Permit Group */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    size="small"
                    options={permitGroups}
                    disableClearable
                    getOptionLabel={(option) => option.label || ""}
                    value={
                      permitGroups.find(
                        (option) => option.id === selectedPermitGroup
                      ) || null
                    }
                    onChange={(_, newValue) => {
                      setSelectedPermitGroup(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Permit Owner/Group"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {/* Meter */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SimpleSelect
                    label="Meter"
                    value={
                      meterRecords.find(
                        (rec) => rec.meter_ndx === selectedMeter
                      )?.meter_ndx || ""
                    }
                    onChange={(e) => {
                      setSelectedMeter(e.target.value);
                      const found = meterRecords.find(
                        (rec) => rec.meter_ndx === e.target.value
                      );
                      setSelectedMeterEntry(found?.meter_reading_ndx || "");
                    }}
                    options={meterRecords.map((rec) => ({
                      id: rec.meter_ndx,
                      label: rec.meter_id || String(rec.meter_ndx),
                    }))}
                  />
                </Grid>
              </Grid>
            </SectionContainer>

            {/* If meter selected, show the reading form */}
            {selectedMeter &&
              selectedMeterEntry &&
              (isLoadingFormData ? (
                <ContentSkeleton />
              ) : (
                <DynamicForm
                  config={meterReadingFormConfig}
                  {...dynamicFormMethods}
                />
              ))}

            {/* If meter was selected but no record found in array */}
            {selectedMeter && !selectedMeterEntry && (
              <Typography
                variant="body1"
                color="error"
                style={{ marginTop: 16 }}
              >
                Selected meter not found in the fetched records.
              </Typography>
            )}
          </>
        )}
      </PageContainer>
    </PageRoot>
  );
}

import React from "react";
import { Box } from "@material-ui/core";
import { useMapWells } from "../hooks/useMapWells";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageAppBar,
  PageContainer,
  PageRoot,
  ErrorCard,
} from "../components/ui";
import { Helmet } from "react-helmet-async";
import Skeleton from "@material-ui/lab/Skeleton";
import Map from "../components/selectLocationMap/Map";
import { useBreakpoints } from "../hooks/useBreakpoints";

const SelectWellPage = () => {
  const { data, isLoading, isFetching, error, refetch } = useMapWells();
  const { isDownSm } = useBreakpoints();

  const gap = isDownSm ? "12px" : "24px";

  return (
    <PageRoot>
      <Helmet title="Select Well | Field Services" />

      <PageAppBar
        title={<strong>Select a Well</strong>}
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Home"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title="Failed to Load Wells"
            message={error?.message}
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : isLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            style={{ gap }}
            mt="8px"
          >
            <Skeleton
              variant="rect"
              width="300px"
              height="56px"
              style={{ maxWidth: "100%" }}
            />
            <Skeleton variant="rect" width="100%" height="100%" />
          </Box>
        ) : (
          <Map data={data} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default SelectWellPage;

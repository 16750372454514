import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  PageRoot,
  PageAppBar,
  PageContainer,
  ErrorCard,
  ContentSkeleton,
  GridItem,
  NavigationButton,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import WellInspectionList from "../components/wellInspection/WellInspectionList";
import { useDynamicForm } from "../hooks/useDynamicForm";
import { wellInspectionFormConfig } from "../formConfigs/wellInspectionFormConfig";
import { DynamicForm } from "../forms/DynamicForm";
import { FieldServicesAppRoutes, INSPECTION_ACTIONS } from "../lib/constants";
import { useListWellInspections } from "../hooks/useListWellInspections";
import { Grid } from "@material-ui/core";

export default function WellInspectionPage() {
  const history = useHistory();
  const { id, inspectionId } = useParams();
  const isNewInspection = inspectionId === "new";
  const isEditingInspection = !!inspectionId && !isNewInspection;

  const {
    data: wellData,
    isFetching: isFetchingWell,
    error: wellError,
    refetch: refetchWell,
  } = useSelectedWell(id);

  const wellNdx = wellData?.well_ndx || null;

  const {
    data: inspectionData,
    isFetching: isFetchingInspection,
    error: inspectionError,
    refetch: refetchInspection,
  } = useListWellInspections({
    wellNdx,
    inspectionId: isEditingInspection ? inspectionId : null,
  });

  const formNdx = !wellNdx ? null : isEditingInspection ? inspectionId : null;
  const redirectPath = FieldServicesAppRoutes.WELL_INSPECTION(id);

  const handleRedirect = () => history.push(redirectPath);

  const {
    isLoading: isLoadingFormData,
    error: formDataError,
    refetchFormData,
    refetchLookups,
    ...rest
  } = useDynamicForm({
    ndx: formNdx,
    isNewRecord: isNewInspection,
    config: wellInspectionFormConfig,
    endpoints: {
      fetch: `/well-actions/${wellNdx}/inspections`,
      submit: `/well-actions/${wellNdx}/inspections`,
    },
    redirectField: "ir_ndx",
    handleRedirect,
    onSuccess: async () => await refetchInspection(),
  });

  const anyError = wellError || inspectionError || formDataError;
  const loading = isFetchingWell || isFetchingInspection || isLoadingFormData;

  const pageTitle = inspectionId ? (
    isNewInspection ? (
      <>
        <strong>New Inspection:</strong> {id}
      </>
    ) : (
      <>
        <strong>Well Inspection:</strong> #{inspectionId} / {id}
      </>
    )
  ) : (
    <>
      <strong>Current Inspections:</strong> {id}
    </>
  );

  const buttonPath = inspectionId
    ? redirectPath
    : FieldServicesAppRoutes.WELL_ACTIONS(id);

  const buttonLabel = inspectionId ? "Well Inspections" : "Well Actions";

  const retryActions = [
    refetchWell,
    refetchInspection,
    refetchLookups,
    isEditingInspection && refetchFormData,
  ].filter(Boolean);

  return (
    <PageRoot>
      <Helmet title="Well Inspection | Field Services" />
      <PageAppBar
        title={pageTitle}
        buttonPath={buttonPath}
        buttonLabel={buttonLabel}
      />
      <PageContainer>
        {anyError ? (
          <ErrorCard
            onRetry={() => Promise.all(retryActions.map((action) => action()))}
            isFetching={loading}
          />
        ) : loading ? (
          <ContentSkeleton />
        ) : !inspectionId ? (
          <WellInspectionList
            wellNdx={wellNdx}
            wellId={id}
            inspections={inspectionData || []}
          />
        ) : (
          <>
            {isEditingInspection && (
              <Grid container spacing={2} style={{ marginBottom: "8px" }}>
                {INSPECTION_ACTIONS(id, inspectionId).map((action, index) => (
                  <GridItem key={index} {...action?.props?.GridItem}>
                    <NavigationButton
                      to={action.route}
                      redirect={action.redirectRoute}
                      label={action.label}
                      {...action?.props?.NavigationButton}
                    />
                  </GridItem>
                ))}
              </Grid>
            )}
            <DynamicForm config={wellInspectionFormConfig} {...rest} />
          </>
        )}
      </PageContainer>
    </PageRoot>
  );
}

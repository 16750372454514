import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import { useAttachedDocumentsAndPhotos } from "../../dataManagement/wells/wellsManagement/hooks/useAttachedDocumentsAndPhotos";
import UploadWells from "../../../components/uploads/UploadWells";
import { Header, SectionContainer } from "../forms/DynamicForm";

const UploadPhotosPage = function () {
  const { id } = useParams();
  const location = useLocation();
  const {
    data: wellData,
    isFetching: isFetchingWell,
    error: wellError,
    refetch: refetchWell,
  } = useSelectedWell(id);

  const wellNdx = wellData?.well_ndx || null;

  const {
    data,
    isLoading,
    error,
    refetch,
    lookups,
    state,
    setter,
    handleUpdateState,
  } = useAttachedDocumentsAndPhotos(wellNdx);

  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirect");

  return (
    <PageRoot>
      <Helmet title="Upload Photos | Field Services" />

      <PageAppBar
        title={
          <>
            <strong>Upload Photos:</strong> {id}
          </>
        }
        buttonPath={redirectPath || FieldServicesAppRoutes.WELL_ACTIONS(id)}
        buttonLabel={redirectPath ? "Back" : "Well Actions"}
      />

      <PageContainer>
        {wellError ? (
          <ErrorCard
            title={`Failed to Load Data for Well ${id}`}
            message={wellError?.message}
            onRetry={refetchWell}
            isFetching={isFetchingWell}
          />
        ) : isFetchingWell ? (
          <ContentSkeleton />
        ) : (
          <SectionContainer>
            <Header variant="h6">Photos & Documents</Header>
            <UploadWells
              selectedNdx={wellNdx}
              data={data}
              isLoading={isLoading}
              error={error}
              refetch={refetch}
              lookups={lookups}
              state={state}
              setter={setter}
              handleUpdateState={handleUpdateState}
            />
          </SectionContainer>
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default UploadPhotosPage;

import { useAxiosInstance } from "./useAxiosInstance";
import { useQuery } from "react-query";
import { USE_QUERY_OPTIONS } from "../lib/constants";

export const useListWellInspections = ({ wellNdx, inspectionId }) => {
  const axiosInstance = useAxiosInstance();
  return useQuery(
    ["well-inspection-data", wellNdx, inspectionId],
    async () => {
      if (!wellNdx || inspectionId) return null;
      const { data } = await axiosInstance.get(
        `/well-actions/${wellNdx}/inspections`
      );
      return data;
    },
    {
      ...USE_QUERY_OPTIONS,
      enabled: Boolean(wellNdx),
    }
  );
};

import { useEffect } from "react";
import { useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAxiosInstance } from "./useAxiosInstance";
import {
  generateSchema,
  sanitizeValues,
  tableNameToRouteName,
} from "../lib/utils";
import { USE_QUERY_CACHE_OPTIONS, USE_QUERY_OPTIONS } from "../lib/constants";
import { useApp } from "../../../AppProvider";
import { formDefaults } from "../formConfigs/formConstants";

const buildDefaultValues = (config) =>
  config
    .flatMap((s) => s.fields)
    .reduce((acc, f) => {
      acc[f.name] = f.defaultValue ?? formDefaults[f.type];
      return acc;
    }, {});

const mergeValues = (config, data) =>
  config
    .flatMap((s) => s.fields)
    .reduce((acc, f) => {
      acc[f.name] = data?.[f.name] ?? f.defaultValue ?? formDefaults[f.type];
      return acc;
    }, {});

export function useDynamicForm({
  ndx,
  isNewRecord,
  config,
  endpoints,
  onSuccess,
  handleRedirect,
}) {
  const { doToast } = useApp();
  const axiosInstance = useAxiosInstance();

  // Initialize react-hook-form
  const schema = generateSchema(config);
  const formMethods = useForm({
    resolver: zodResolver(schema),
    defaultValues: buildDefaultValues(config),
  });

  // Fetch form data from the server
  const formQuery = useQuery(
    ["formData", ndx],
    async () => {
      const { data } = await axiosInstance.get(`${endpoints.fetch}/${ndx}`);
      return data;
    },
    {
      ...USE_QUERY_OPTIONS,
      enabled: Boolean(ndx) && !isNewRecord,
    }
  );

  // Fetch lookup tables
  const distinctLookups = Array.from(
    new Set(
      config.flatMap((section) =>
        section.fields.filter((f) => f.lookupTable).map((f) => f.lookupTable)
      )
    )
  );

  const lookupQueries = useQueries(
    distinctLookups?.map(({ tableName }) => ({
      queryKey: ["lookup", tableName],
      queryFn: async () => {
        const routeName = tableNameToRouteName(tableName);
        const { data } = await axiosInstance.get(`/lookup/${routeName}`);
        return data;
      },
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
    }))
  );

  // Aggregate lookup data
  const lookupsData = distinctLookups.reduce(
    (acc, { tableName, idField, labelField }, i) => {
      acc[tableName] =
        lookupQueries[i]?.data?.map((item) => ({
          id: item?.[idField],
          label: item?.[labelField],
        })) || [];
      return acc;
    },
    {}
  );

  // Initialize form values
  useEffect(() => {
    if (isNewRecord) {
      formMethods.reset(buildDefaultValues(config));
    } else if (formQuery.data) {
      formMethods.reset(mergeValues(config, formQuery.data));
    }
  }, [config, formMethods, formQuery.data, isNewRecord]);

  const refetchLookups = async () => {
    await Promise.all(lookupQueries.map((query) => query.refetch()));
  };

  const isLoading =
    formQuery.isLoading || lookupQueries.some((query) => query.isLoading);

  const error = formQuery.error || lookupQueries.some((query) => query.error);

  // onSubmit -> POST if creating, otherwise PUT
  const onSubmit = async (values) => {
    try {
      const sanitizedValues = sanitizeValues(config, values);

      let response;
      if (isNewRecord) {
        response = await axiosInstance.post(endpoints.submit, sanitizedValues);
      } else {
        response = await axiosInstance.put(
          `${endpoints.submit}/${ndx}`,
          sanitizedValues
        );
      }

      doToast("success", "Form submitted successfully.");

      if (onSuccess) {
        onSuccess(response.data);
      }

      if (handleRedirect) {
        handleRedirect(response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      const message =
        error.response?.data?.message || error.message || "Submission failed.";
      doToast("error", message);
    }
  };

  return {
    isLoading,
    error,
    lookupsData,
    formMethods,
    onSubmit,
    refetchFormData: formQuery.refetch,
    refetchLookups,
  };
}

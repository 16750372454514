import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Divider as MuiDivider,
  Paper,
  Container,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { RebuildMapLayerButton } from "./RebuildMapLayerButton";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const StyledPaper = styled(Paper)`
  padding: 24px;
  margin: 24px auto;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export default function RebuildMapWellsLayer() {
  return (
    <>
      <Helmet title="Rebuild Map Wells Layer" />

      <Typography variant="h3" gutterBottom display="inline">
        Rebuild Map Wells Layer
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Rebuild Map Wells Layer</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Container maxWidth="md">
        <Alert severity="info">
          <Typography variant="body1" paragraph>
            To improve performance for editing and data entry, most edits to
            wells, permits, and associated information will not force a re-build
            of the wells map layer. The map is refreshed nightly at 3 AM and
            also after the specific actions listed below. Use this page to force
            a refresh when other changes have been made and you want them to be
            reflected on the map immediately.
          </Typography>
          <ul>
            <li>
              A new well is created (but not for subsequent edits of that new
              well's information, except for the changes included below)
            </li>
            <li>A well's published status is changed</li>
            <li>A well's removed status is changed</li>
            <li>A well's lat/lon coordinates are changed</li>
          </ul>
        </Alert>
        <StyledPaper elevation={3}>
          <Typography variant="h6" style={{ marginBottom: "16px" }}>
            Force Refresh of Wells Map Layer
          </Typography>
          <RebuildMapLayerButton />
        </StyledPaper>
      </Container>
    </>
  );
}

export const BASE_PATH = "/field-services";

export const FieldServicesAppRoutes = {
  DASHBOARD: () => "/dashboard",
  HOME: () => BASE_PATH,
  CREATE_WELL: () => `${BASE_PATH}/create-well`,
  SELECT_WELL: () => `${BASE_PATH}/select-well`,
  METER_READING: () => `${BASE_PATH}/meter-reading`,
  WELL_ACTIONS: (id) => (id ? `${BASE_PATH}/well-actions/${id}` : BASE_PATH),
  WELL_INSPECTION: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/inspection` : BASE_PATH,
  WELL_INSPECTION_EDIT: (id, inspectionId) =>
    id && inspectionId
      ? `${BASE_PATH}/well-actions/${id}/inspection/${inspectionId}`
      : BASE_PATH,
  WELL_RECORD: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/record` : BASE_PATH,
  WATER_LEVELS: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/water-levels` : BASE_PATH,
  WATER_QUALITY: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/water-quality` : BASE_PATH,
  UPLOAD_PHOTOS: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/upload-photos` : BASE_PATH,
  FIELD_NOTES: (id) =>
    id ? `${BASE_PATH}/well-actions/${id}/field-notes` : BASE_PATH,
};

export const HOME_ACTIONS = [
  { label: "Create a Well", route: FieldServicesAppRoutes.CREATE_WELL() },
  { label: "Select a Well", route: FieldServicesAppRoutes.SELECT_WELL() },
  {
    label: "Enter a Meter Reading",
    route: FieldServicesAppRoutes.METER_READING(),
  },
];

export const WELL_ACTIONS = (id) => [
  {
    label: "Well Inspection",
    route: FieldServicesAppRoutes.WELL_INSPECTION(id),
    props: {
      GridItem: { sm: 12, md: 8, lg: 8 },
      NavigationButton: {
        style: {},
      },
    },
  },
  {
    label: "Well Record",
    route: FieldServicesAppRoutes.WELL_RECORD(id),
    props: {
      GridItem: { xs: 6, lg: 4 },
      NavigationButton: {},
    },
  },
  {
    label: "Upload Photos",
    route: FieldServicesAppRoutes.UPLOAD_PHOTOS(id),
    props: {
      GridItem: { xs: 6, lg: 4 },
      NavigationButton: {},
    },
  },
  {
    label: "Water Levels",
    route: FieldServicesAppRoutes.WATER_LEVELS(id),
    props: {
      GridItem: { xs: 6, lg: 4 },
      NavigationButton: {},
    },
  },
  {
    label: "Water Quality",
    route: FieldServicesAppRoutes.WATER_QUALITY(id),
    props: {
      GridItem: { xs: 6, lg: 4 },
      NavigationButton: {},
    },
  },
  {
    label: "Switch Well",
    route: FieldServicesAppRoutes.SELECT_WELL(),
    props: {
      GridItem: {
        style: { flexBasis: "auto", alignSelf: "center" },
      },
      NavigationButton: { color: "secondary" },
    },
  },
];

export const INSPECTION_ACTIONS = (id, inspectionId) => [
  {
    label: "Well Record",
    route: FieldServicesAppRoutes.WELL_RECORD(id),
    redirectRoute: FieldServicesAppRoutes.WELL_INSPECTION_EDIT(
      id,
      inspectionId
    ),
    props: {
      GridItem: { xs: 6, md: 3 },
      NavigationButton: {},
    },
  },
  {
    label: "Upload Photos",
    route: FieldServicesAppRoutes.UPLOAD_PHOTOS(id),
    redirectRoute: FieldServicesAppRoutes.WELL_INSPECTION_EDIT(
      id,
      inspectionId
    ),
    props: {
      GridItem: { xs: 6, md: 3 },
      NavigationButton: {},
    },
  },
  {
    label: "Water Levels",
    route: FieldServicesAppRoutes.WATER_LEVELS(id),
    redirectRoute: FieldServicesAppRoutes.WELL_INSPECTION_EDIT(
      id,
      inspectionId
    ),
    props: {
      GridItem: { xs: 6, md: 3 },
      NavigationButton: {},
    },
  },
  {
    label: "Water Quality",
    route: FieldServicesAppRoutes.WATER_QUALITY(id),
    redirectRoute: FieldServicesAppRoutes.WELL_INSPECTION_EDIT(
      id,
      inspectionId
    ),
    props: {
      GridItem: { xs: 6, md: 3 },
      NavigationButton: {},
    },
  },
];

export const USE_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  keepPreviousData: true,
};

export const USE_QUERY_CACHE_OPTIONS = {
  cacheTime: Infinity,
  staleTime: Infinity,
};

export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

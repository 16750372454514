import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { useTriggerRebuildMapLayer } from "./useTriggerRebuildMapLayer";

export const RebuildMapLayerButton = ({
  fullWidth = false,
  variant = "contained",
  size = "large",
}) => {
  const { triggerRebuild, isLoading } = useTriggerRebuildMapLayer();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);
  const handleConfirm = () => {
    triggerRebuild(undefined);
    handleCloseConfirm();
  };

  return (
    <>
      <Button
        fullWidth={fullWidth}
        style={{ minWidth: "200px" }}
        variant={variant}
        size={size}
        color="primary"
        onClick={handleOpenConfirm}
        startIcon={isLoading ? null : <SendIcon />}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Refresh Map Layer"}
      </Button>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm Rebuild</DialogTitle>
        <DialogContent>
          <Typography>
            This will trigger an immediate update of the wells map layer.
          </Typography>
          <Typography>
            The process may take up to <strong>20 seconds</strong>. Please do
            not navigate away until it completes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirm}
            color="primary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "100px" }}
            onClick={handleConfirm}
            color="secondary"
            variant="contained"
            autoFocus
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

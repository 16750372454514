import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FileChip from "../components/FileChip";

const FileList = ({
  selectedFiles,
  totalSizeMB,
  handleRemoveAllFiles,
  handleRemoveFile,
  isUploading,
  fileErrors,
}) => {
  return (
    <>
      {selectedFiles.length > 0 && (
        <Box
          border={1}
          borderColor="divider"
          borderRadius={1}
          bgcolor="#f5f5f5"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: "12px",
              position: "sticky",
              top: 0,
              backgroundColor: "#f5f5f5",
              zIndex: 1,
              borderBottom: "1px solid #ddd",
            }}
          >
            <Typography variant="subtitle1">
              Selected files ({selectedFiles.length}): {totalSizeMB.toFixed(2)}{" "}
              MB
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleRemoveAllFiles}
              disabled={isUploading}
            >
              Remove All
            </Button>
          </Box>

          <Box m={2}>
            {selectedFiles.map((file, idx) => (
              <FileChip
                key={idx}
                file={file}
                onDelete={() => handleRemoveFile(idx)}
              />
            ))}
          </Box>
        </Box>
      )}

      {fileErrors && (
        <Box mt={1}>
          <Alert severity="error">
            {fileErrors?.message ||
              "An unexpected error occurred. Please try again."}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default FileList;

import { z } from "zod";

export const MAX_FILE_SIZE_MB = 10;
export const MAX_TOTAL_SIZE_MB = 50;

/**
 * Zod schema: ensures the required fields, checks for .txt only,
 * enforces per-file and total-size limits.
 */
export const uploadLoggerDataSchema = z.object({
  description: z.string().min(1, "Description is required"),
  uploadedBy: z.string().min(1, "Please specify who is uploading this file"),
  fileFormat: z.number().min(1, "File format is required"),
  selectedFiles: z
    .array(z.instanceof(File))
    .min(1, "Please select at least one file.")
    .refine(
      (files) => files.every((file) => file.type === "text/plain"),
      "Only .txt files are allowed"
    )
    .refine(
      (files) =>
        files.every((file) => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024),
      `Each file must be smaller than ${MAX_FILE_SIZE_MB}MB`
    )
    .refine(
      (files) =>
        files.reduce((sum, file) => sum + file.size, 0) <=
        MAX_TOTAL_SIZE_MB * 1024 * 1024,
      `Total upload size must be less than ${MAX_TOTAL_SIZE_MB}MB`
    ),
});

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useQuery } from "react-query";

export const useActiveUpload = (isUploading) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(
    "activeUpload",
    async () => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/upload-logger-data/active`,
        { headers }
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !isUploading, // only fetch if we're not already uploading
    }
  );
};

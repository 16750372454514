import React from "react";
import { TextField } from "@material-ui/core";
import { getPointerEventsStyles, getReadOnlyStyles } from "../../lib/styles";

export function NumberInput({ fieldConfig, controllerField, fieldState }) {
  const { readOnly } = fieldConfig;
  const { value, onChange, ...rest } = controllerField;

  const handleChange = (e) => {
    const numericValue = e.target.valueAsNumber;
    onChange(isNaN(numericValue) ? null : numericValue);
  };

  const displayValue = value !== null && !isNaN(value) ? value.toString() : "";

  return (
    <TextField
      {...rest}
      value={displayValue}
      disabled={readOnly}
      onChange={handleChange}
      variant="outlined"
      size="small"
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      style={getReadOnlyStyles(readOnly)}
      InputProps={{
        style: getPointerEventsStyles(readOnly),
        type: "number",
      }}
      fullWidth
    />
  );
}

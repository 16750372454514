import { ValidationSchemas, lookupConfig } from "./formConstants";
import { z } from "zod";

export const waterLevelsFormConfig = (userId = "") => [
  {
    title: "General Information",
    fields: [
      {
        name: "collect_timestamp",
        label: "Collection Date/Time",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        defaultValue: new Date().toISOString(),
      },
      {
        name: "is_pumping",
        label: "Well Pumping?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: true,
      },
      {
        name: "has_eline_access_port",
        label: "E-Line Access Port?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "has_conductor_pipe",
        label: "Conductor Pipe?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
    ],
  },
  {
    title: "Measurement Details",
    fields: [
      {
        name: "wl_meas_method_ndx",
        label: "Water Level Measurement Method",
        type: "selectSingle",
        lookupTable: lookupConfig.waterLevelMeasurementMethods,
        validation: ValidationSchemas.positiveInt,
      },
      {
        name: "wl_meas_from_ndx",
        label: "Measured From",
        type: "selectSingle",
        lookupTable: lookupConfig.waterLevelMeasuredFrom,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 1,
      },
      {
        name: "aqsci_data_source_ndx",
        label: "Source of Measurements",
        type: "selectSingle",
        lookupTable: lookupConfig.waterLevelSourceOfMeasurements,
        validation: ValidationSchemas.positiveInt,
      },
      {
        name: "wl_meas_point_height_in",
        label: "Measurement Point Height (inches)",
        type: "number",
        validation: z.number().catch(0),
        defaultValue: 0,
      },
    ],
  },
  {
    title: "Water Level Readings",
    fields: [
      {
        name: "wl_meas_val_ft",
        label: "Depth to Groundwater (ft)",
        type: "number",
        validation: z.number(),
      },
      {
        name: "wl_meas_val_ft_2",
        label: "Depth to Groundwater (ft) - Optional 1",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "wl_meas_val_ft_3",
        label: "Depth to Groundwater (ft) - Optional 2",
        type: "number",
        validation: ValidationSchemas.number,
      },
    ],
  },
  {
    title: "Additional Information",
    fields: [
      {
        name: "remarks",
        label: "Notes",
        type: "text",
        validation: ValidationSchemas.string.nullable().optional(),
      },
      {
        name: "sampled_by",
        label: "Sampled By",
        type: "text",
        validation: ValidationSchemas.string,
        defaultValue: userId,
        readOnly: true,
        adminOnly: true,
      },
    ],
  },
];

import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import {
  FieldServicesAppRoutes,
  USE_QUERY_CACHE_OPTIONS,
  USE_QUERY_OPTIONS,
} from "../lib/constants";

import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";

import { useSelectedWell } from "../hooks/useSelectedWell";
import { useApp } from "../../../AppProvider";
import { useAxiosInstance } from "../hooks/useAxiosInstance";
import { WaterQualityForm } from "../forms/WaterQualityForm";

async function fetchLookupParameters(axios) {
  return await axios.get("/lookup/ui-list-wq-parameters").then((res) =>
    res.data.map((item) => ({
      id: item.parameter_ndx,
      label: item.param_abbrev,
      dataentry_primary: item.dataentry_primary,
    }))
  );
}

function fetchLookupDataSources(axios) {
  return axios.get("/lookup/ui-list-wq-sources").then((res) =>
    res.data.map((item) => ({
      id: item.aqsci_data_source_ndx,
      label: item.aqsci_data_source_desc,
    }))
  );
}

function fetchLookupLabs(axios) {
  return axios.get("/lookup/ui-list-wq-labs").then((res) =>
    res.data.map((item) => ({
      id: item.aqsci_lab_ndx,
      label: item.aqsci_lab_desc,
    }))
  );
}

export default function WaterQualityPage() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { doToast } = useApp();
  const axiosInstance = useAxiosInstance();

  const {
    data: wellData,
    isFetching: isFetchingWell,
    error: wellError,
    refetch: refetchWell,
  } = useSelectedWell(id);

  const {
    data: paramLookup,
    isFetching: fetchingParams,
    error: paramError,
    refetch: refetchParams,
  } = useQuery(["wqParameters"], () => fetchLookupParameters(axiosInstance), {
    ...USE_QUERY_OPTIONS,
    ...USE_QUERY_CACHE_OPTIONS,
    enabled: !!axiosInstance,
  });

  const {
    data: dataSourceLookup,
    isFetching: fetchingDataSources,
    error: dataSourceError,
    refetch: refetchDataSources,
  } = useQuery(["wqDataSources"], () => fetchLookupDataSources(axiosInstance), {
    ...USE_QUERY_OPTIONS,
    ...USE_QUERY_CACHE_OPTIONS,
    enabled: !!axiosInstance,
  });

  const {
    data: labLookup,
    isFetching: fetchingLabs,
    error: labError,
    refetch: refetchLabs,
  } = useQuery(["wqLabs"], () => fetchLookupLabs(axiosInstance), {
    ...USE_QUERY_OPTIONS,
    ...USE_QUERY_CACHE_OPTIONS,
    enabled: !!axiosInstance,
  });

  const loading =
    isFetchingWell || fetchingParams || fetchingDataSources || fetchingLabs;
  const anyError = wellError || paramError || dataSourceError || labError;
  const retryActions = [
    refetchWell,
    refetchParams,
    refetchDataSources,
    refetchLabs,
  ].filter(Boolean);

  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirect");

  const handleRedirect = () => {
    history.push(redirectPath || FieldServicesAppRoutes.WELL_ACTIONS(id));
  };

  const wellNdx = wellData?.well_ndx || null;

  return (
    <PageRoot>
      <Helmet title="Water Quality | Field Services" />

      <PageAppBar
        title={
          <>
            <strong>New WQ Entry:</strong> {id}
          </>
        }
        buttonPath={redirectPath || FieldServicesAppRoutes.WELL_ACTIONS(id)}
        buttonLabel={redirectPath ? "Back" : "Well Actions"}
      />

      <PageContainer>
        {anyError ? (
          <ErrorCard
            onRetry={() => Promise.all(retryActions.map((fn) => fn()))}
            isFetching={loading}
          />
        ) : loading ? (
          <ContentSkeleton />
        ) : (
          <WaterQualityForm
            wellNdx={wellNdx}
            paramLookup={paramLookup}
            dataSourceLookup={dataSourceLookup}
            labLookup={labLookup}
            onSuccess={() => {
              doToast("success", "Water Quality record created successfully.");
              handleRedirect();
            }}
            onError={(errMsg) => {
              doToast(
                "error",
                errMsg || "Failed to create Water Quality record."
              );
            }}
          />
        )}
      </PageContainer>
    </PageRoot>
  );
}

import { ValidationSchemas, lookupConfig } from "./formConstants";
import { z } from "zod";

export const wellInspectionFormConfig = [
  {
    title: "Inspection Details",
    fields: [
      {
        name: "inspection_date",
        label: "Inspection Date",
        type: "date",
        validation: ValidationSchemas.date,
        defaultValue: new Date().toISOString(),
      },
      {
        name: "ir_type_ndx",
        label: "Visit Type",
        type: "selectSingle",
        lookupTable: lookupConfig.inspectionVisitTypes,
        validation: ValidationSchemas.positiveInt,
      },
      {
        name: "inspected_by",
        label: "Inspected By (Personnel)",
        type: "text",
        validation: z.string().min(1),
      },
    ],
  },
  {
    title: "Notes and Inspection Checklist",
    fields: [
      {
        name: "field_notes",
        label: "Notes",
        type: "text",
        validation: ValidationSchemas.string,
        defaultValue: false,
      },
      {
        name: "chk_identity",
        label: "Contact Information",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_location",
        label: "Location",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_use",
        label: "Use",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_setbacks",
        label: "Setbacks",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_meter",
        label: "Meter",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_equipment",
        label: "Equipment",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_treatment",
        label: "Treatment",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_surf_compl",
        label: "Surface Completion",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "chk_classification",
        label: "Classification",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
    ],
  },
  {
    title: "Record Tracking",
    adminOnly: true,
    fields: [
      {
        name: "created_by",
        label: "Created By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
      },
      {
        name: "created_timestamp",
        label: "Created Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
      },
      {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
      },
      {
        name: "updated_timestamp",
        label: "Updated Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
      },
    ],
  },
];

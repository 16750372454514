import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled from "styled-components/macro";
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";

import { useApp } from "../../../AppProvider";
import { useFileFormatsQuery } from "./hooks/useFileFormatsQuery";
import { useActiveUpload } from "./hooks/useActiveUpload";

import InstructionsAlert from "./components/InstructionsAlert";
import ConfirmationDialog from "./components/ConfirmationDialog";
import UploadProgress from "./components/UploadProgress";

import FileFormatSelect from "./components/FileFormatSelect";
import DragAndDropArea from "./components/DragAndDropArea";
import FileList from "./components/FileList";

import {
  uploadLoggerDataSchema,
  MAX_FILE_SIZE_MB,
  MAX_TOTAL_SIZE_MB,
} from "./constants";

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
  .MuiAlert-message {
    width: 100%;
  }
`;

const UploadLoggerData = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const queryClient = useQueryClient();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [formDataForUpload, setFormDataForUpload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef = useRef();
  const pollingIntervalRef = useRef(null);

  // Query for file formats
  const {
    data: fileFormats = [],
    isLoading: isFileFormatsLoading,
    isError: isFileFormatsError,
    error: fileFormatsError,
  } = useFileFormatsQuery();

  // Hook Form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(uploadLoggerDataSchema),
    defaultValues: {
      description: "",
      uploadedBy: "",
      fileFormat: undefined,
      selectedFiles: [],
    },
  });

  const selectedFiles = watch("selectedFiles");
  const fileFormat = watch("fileFormat");

  // Drag & Drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "text/plain": [".txt"] },
    multiple: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setValue("selectedFiles", [...selectedFiles, ...acceptedFiles], {
          shouldValidate: true,
        });
      }
    },
  });

  // Auto-pick a default file format (if none selected)
  useEffect(() => {
    if (fileFormats.length > 0 && fileFormat === undefined) {
      setValue("fileFormat", fileFormats[0].file_format_ndx);
    }
  }, [fileFormats, fileFormat, setValue]);

  // Handle browse input
  const handleFileInput = (e) => {
    const files = e.target.files;
    if (files) {
      const fileArray = Array.from(files);
      setValue("selectedFiles", [...selectedFiles, ...fileArray], {
        shouldValidate: true,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // Remove a single file
  const handleRemoveFile = (index) => {
    const updated = [...selectedFiles];
    updated.splice(index, 1);
    setValue("selectedFiles", updated, { shouldValidate: true });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Remove all
  const handleRemoveAllFiles = () => {
    setValue("selectedFiles", [], { shouldValidate: true });
  };

  // Form submission
  const onSubmit = (data) => {
    setFormDataForUpload(data);
    setConfirmDialogOpen(true);
  };

  const handleConfirm = () => {
    startUpload(formDataForUpload);
    setConfirmDialogOpen(false);
  };
  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  // Mutation for starting upload
  const { mutate: startUpload } = useMutation(
    async (formData) => {
      setIsUploading(true);
      const token = await getAccessTokenSilently();

      const { selectedFiles, description, uploadedBy, fileFormat } = formData;
      const apiFormData = new FormData();

      selectedFiles.forEach((file) => {
        apiFormData.append("files", file);
      });
      apiFormData.append("description", description);
      apiFormData.append("uploadedBy", uploadedBy);
      apiFormData.append("fileFormat", fileFormat.toString());

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/upload-logger-data`,
        apiFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        startPolling(data.uploadId);
      },
      onError: (error) => {
        setIsUploading(false);
        const errorMessage =
          error?.response?.data?.message ||
          error.message ||
          "Failed to start upload";
        doToast("error", errorMessage);
      },
    }
  );

  // Polling for progress
  const startPolling = useCallback(
    (uploadId) => {
      const checkStatus = async () => {
        try {
          const token = await getAccessTokenSilently();
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/upload-logger-data/status/${uploadId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          setUploadProgress(data.progress);

          if (data.status === "completed" || data.status === "failed") {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
            setIsUploading(false);

            // Force re-fetch any active uploads
            queryClient.invalidateQueries("activeUpload");

            if (data.status === "completed") {
              doToast(
                "success",
                `Upload completed - ${data.fileCount} files processed`
              );
              reset();
            } else {
              doToast("error", data.error || "Upload failed");
            }
          }
        } catch (err) {
          console.error("Polling error:", err);
        }
      };

      pollingIntervalRef.current = setInterval(checkStatus, 2000);
    },
    [getAccessTokenSilently, doToast, reset, queryClient]
  );

  // Clear polling interval on unmount
  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  // Resume if an active upload is found
  const activeUploadQuery = useActiveUpload(isUploading);
  useEffect(() => {
    if (
      activeUploadQuery.data &&
      activeUploadQuery.data.length > 0 &&
      !isUploading
    ) {
      const processingUploads = activeUploadQuery.data.filter(
        (u) => u.status === "processing"
      );
      if (processingUploads.length > 0) {
        processingUploads.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        const oldest = processingUploads[0];
        setIsUploading(true);
        startPolling(oldest.id);
      }
    }
  }, [activeUploadQuery.data, isUploading, startPolling]);

  // Calculate total size
  const totalSizeMB =
    selectedFiles.reduce((sum, file) => sum + file.size, 0) / 1024 / 1024;

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: "32px", borderRadius: "8px" }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Upload Logger Data
          </Typography>
          <InstructionsAlert />

          {isUploading && (
            <StyledAlert severity="warning">
              <AlertTitle>Upload in progress.</AlertTitle>
              Please be patient while we process your files.
              <UploadProgress progress={uploadProgress} />
            </StyledAlert>
          )}

          {isFileFormatsError && (
            <StyledAlert severity="error">
              <AlertTitle>Error</AlertTitle>
              Could not load file formats: {fileFormatsError?.message}
            </StyledAlert>
          )}

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb={3}>
              <FileFormatSelect
                control={control}
                fileFormat={fileFormat}
                fileFormats={fileFormats}
                isUploading={isUploading}
                isFileFormatsLoading={isFileFormatsLoading}
                errors={errors}
                setValue={setValue}
              />
            </Box>

            <Box mb={3}>
              <TextField
                label="Description"
                fullWidth
                autoComplete="off"
                disabled={isUploading}
                {...register("description")}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Box>

            <Box mb={3}>
              <TextField
                label="Uploaded By"
                fullWidth
                autoComplete="off"
                disabled={isUploading}
                {...register("uploadedBy")}
                error={!!errors.uploadedBy}
                helperText={errors.uploadedBy?.message}
              />
            </Box>

            <Box mb={3}>
              <DragAndDropArea
                isDragActive={isDragActive}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isUploading={isUploading}
                hasError={!!errors.selectedFiles}
                fileInputRef={fileInputRef}
                handleFileInput={handleFileInput}
                maxFileSizeMB={MAX_FILE_SIZE_MB}
                maxTotalSizeMB={MAX_TOTAL_SIZE_MB}
              />
            </Box>

            <Box mb={3}>
              <FileList
                selectedFiles={selectedFiles}
                totalSizeMB={totalSizeMB}
                handleRemoveAllFiles={handleRemoveAllFiles}
                handleRemoveFile={handleRemoveFile}
                isUploading={isUploading}
                fileErrors={errors.selectedFiles}
              />
            </Box>

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isUploading || isFileFormatsLoading}
              fullWidth
            >
              {isUploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit Files"
              )}
            </Button>
          </form>
        </Box>
      </Paper>

      <ConfirmationDialog
        open={confirmDialogOpen}
        files={formDataForUpload?.selectedFiles || []}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </Container>
  );
};

export default UploadLoggerData;
